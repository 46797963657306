<ng-container *ngIf="children">
  <div *ngFor="let child of children; let i = index;"
       class="children-container text-center clickable"
       [ngClass]="{'active': child.isSelected}"
       (click)="selectItem(i)" tappable
  >
    <div class="w-100  colored-row" [style.background]="child.color"></div>
    <p class="fw-700 font-size blue-dark">{{ child.name }}</p>
  </div>
</ng-container>
