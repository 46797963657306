import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavController} from "@ionic/angular";
import {debounceTime, Subject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  @Input() onlySearchInput = false;
  @Input() searchQuery: null | string = '';
  @Input() mode: 'challenge' | 'simple' = 'challenge';
  @Output() search = new EventEmitter<string>();

  searchQueryUpdate = new Subject<string>();
  constructor(
    private navCtrl: NavController
  ) {}

  ngOnInit() {
    this.searchQueryUpdate.pipe(
      debounceTime(3000),
      distinctUntilChanged())
      .subscribe(value => {
        switch (this.mode) {
          case "challenge":
            this.navCtrl.navigateForward(`pages/challenge-results/${value}`)
            break;
          case "simple":
            this.navCtrl.navigateForward(`pages/favorites/${value}`)
            break;
          default:
            this.navCtrl.navigateForward(`pages/challenge-results/${value}`)
            break;
        }
      });
  }

  showResults(value: string) {
    this.navCtrl.navigateForward(`pages/challenge-results/${value}`)
  }

  searchNavigation() {
    if (this.searchQuery) {
      this.showResults(this.searchQuery);
    } else {
      this.navCtrl.navigateForward(`/pages/challenge`)
    }
  }
}
