<ng-container *ngIf="stepNumber === 1">
  <div cdkDropListGroup>
    <div class="drop-container">
      <div class="placeholder-text">
        {{ isDropped ? selectedAnswer?.title :
           'Húzd ide a helyes választ tartalmazó szövegrészletet.' }}
        <div class="answer-countdown " *ngIf="isDropped">
          <app-countdown
            [duration]="3"
            [fontSize]="12"
            [color]="'black'"
            [paused]="false"
            (countdownFinished)="isDropped = false; handleCountdownFinished()"
          ></app-countdown>
        </div>
      </div>
      <div
        cdkDropList
        [cdkDropListData]="fakeItems"
        class="example-list"
        cdkDropListSortingDisabled
        (cdkDropListDropped)="drop($event)">
        <div class="example-box" style="color:transparent" *ngFor="let item of fakeItems" cdkDrag>{{item}}</div>
      </div>
    </div>

    <div class="example-container">
      <div
        cdkDropList
        cdkDropListSortingDisabled
        class="example-list-from"
        (cdkDropListDropped)="drop($event)">
        <div
          cdkDrag
          class="quiz-answer shadow-basic d-flex "
          *ngFor="let answer of answers; let index = index;"
          [ngClass]="{'selected': answer.isSelected}"
          [cdkDragData]="answer"
          (cdkDragStarted)="dragStarted()"
          (cdkDragDropped)="dragDropped(index)"
          cdkDragPreviewClass="drag-preview"
          style="background: {{ answerClickedColorSetting[answer.status].backgroundColor }}"
        >
          <span class="answer-title-icon">
            <app-icon [iconName]="'answer-title-icon.svg'"></app-icon>
          </span>
          <div class="answer-title d-flex-center"
               style="color: {{ answerClickedColorSetting[answer.status].textColor }}"
          >

            <span class="answer-title-text">
              {{ answer.title }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>

<ng-container *ngIf="stepNumber === 2">
  <div
    class="quiz-answer shadow-basic d-flex "
    style="background: {{ answerClickedColorSetting.goodAnswer.backgroundColor }}"
  >
    <div class="answer-title d-flex-center"
         style="color: {{ answerClickedColorSetting.goodAnswer.textColor }}"
    >
      {{ correctAnswer?.title }}
    </div>
  </div>
  <ng-container *ngIf="isCorrect === false">
    <div
      class="quiz-answer shadow-basic d-flex "
      style="background: {{ answerClickedColorSetting.wrongAnswer.backgroundColor }}"
    >
      <div class="answer-title d-flex-center"
           style="color: {{ answerClickedColorSetting.wrongAnswer.textColor }}"
      >
        {{ selectedAnswer?.title }}
      </div>
    </div>
  </ng-container>

  <ng-template #incorrectAnswer>
    <div class="correction">{{ successAnswerText }}</div>
  </ng-template>

  <div *ngIf="isCorrect === true else incorrectAnswer" class="correction">{{ answerCorrectionText }}</div>


  <div class="quiz-actions">
    <div class="separator"></div>
    <app-button (click)="next()">
      Következő
    </app-button>
  </div>
</ng-container>
