<div class="modal-quiz" [ngClass]="{'isInProgress': !isInProgress}">
  <div *ngIf="isInProgress" class="modal-quiz-in-progress">
    <div class="modal-header background">
      <div class="title">
        {{ quizTitle }}
      </div>
      <div class="subtitle">
        {{ quizSubTitle }}
      </div>
      <div class="quiz-id-container">
        <div class="icon">
          <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00333333 6L7 3L0.00333333 0L0 2.33333L5 3L0 3.66667L0.00333333 6Z" fill="white"/>
          </svg>
        </div>
        <div class="quiz-name">
          {{ quizName }}
        </div>
      </div>
    </div>
    <div class="quiz-content">
      <div class="quiz-top-container">
        <div class="quiz-top-text">
        <span *ngIf="type === 'list'">
          Jelöld meg a helyes választ!
        </span>
          <span *ngIf="type === 'text'">
           Gondold végig és jegyzeteld le!
        </span>
          <span *ngIf="type === 'yesno'">
          Igaz vagy hamis?
        </span>
          <span *ngIf="type === 'select'">
          A legördülő listában jelöld meg a helyes választ!
        </span>
          <span *ngIf="type === 'draganddrop'">
          Húzd be a helyes választ!
        </span>
        </div>
        <div *ngIf="to > 1" class="quiz-top-counter">
          <div class="bubble-inner__item right-bubble">
            <div class="counter-bubble">
              <span class="counter">{{from}}/{{to}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="quiz-question">
        {{ question }}
      </div>

      <div *ngIf="type === 'list'">
        <app-answer-list
                [answers]="answers"
                [answerClickedColorSetting]="answerClickedColorSetting"
                (nextEmitter)="enlargeFrom($event)"
        ></app-answer-list>
      </div>
      <div *ngIf="type === 'yesno'">
        <app-answer-list
                [answers]="answers"
                [answerClickedColorSetting]="answerClickedColorSetting"
                (nextEmitter)="enlargeFrom($event)"
        ></app-answer-list>
      </div>
      <div *ngIf="type === 'select'">
        <app-answer-select
                [answers]="answers"
                [answerClickedColorSetting]="answerClickedColorSetting"
                (nextEmitter)="enlargeFrom($event)"
        ></app-answer-select>
      </div>
      <div *ngIf="type === 'draganddrop'">
        <app-answer-drag-and-drop
                [answers]="answers"
                [answerClickedColorSetting]="answerClickedColorSetting"
                (nextEmitter)="enlargeFrom($event)"
        ></app-answer-drag-and-drop>
      </div>
      <div *ngIf="type === 'text'">
        <app-answer-text
                [answers]="answers"
                [quiz]="quiz"
                [answerClickedColorSetting]="answerClickedColorSetting"
                (nextEmitter)="enlargeFrom($event)"
        ></app-answer-text>
      </div>
    </div>
  </div>
  <div  *ngIf="!isInProgress" class="modal-quiz-end">
    <div *ngIf="percentage >= percentageWhenPassed" class="success">
      <h2 class="text-bold">
        Gratulálunk! Sikeresen befejezted a kvízt!
      </h2>
      <p>
        Csak így tovább! Jó úton haladsz ahhoz, hogy ezt a technikát is elsajátítsd!
      </p>
      <div class="modal-quiz-end-image">
        <img
          src="assets/quiz-end-success-image.png"
          decoding="sync"
          priority=""
          loading="eager"
          fetchpriority="high"
          alt="quiz-end-image"
          height="2500" width="1467">
      </div>
    </div>
    <div *ngIf="percentage < percentageWhenPassed" class="unsuccess">
      <h2 class="text-bold">
        Semmi baj, majd legközelebb jobban megy, számtalanszor van, hogy nem sikerül 😊 Tarts ki! Szeretnéd újra kitölteni?
      </h2>
      <div class="modal-quiz-end-image unsuccess">
        <img
          src="assets/quiz-end-unsuccess-image.png"
          decoding="sync"
          priority=""
          loading="eager"
          fetchpriority="high"
          alt="quiz-end-image"
          height="2500" width="976">
      </div>
    </div>

    <ng-template #optionsToContinue>
      <app-button (click)="restartQuiz()" tappable [color]="'secondary'">
        Igen, csináljuk
      </app-button>
      <div class="mt-10">
        <app-button (click)="close()" tappable [color]="'primary'">
          Köszönöm, inkább majd máskor
        </app-button>
      </div>
    </ng-template>

    <ng-container *ngIf="percentage >= percentageWhenPassed else optionsToContinue">
      <app-button class="mt-20" (click)="close()" tappable [color]="'secondary'">
          Pompás
      </app-button>
    </ng-container>

  </div>
</div>
