import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: 'icon.component.html',
  styleUrls: ['icon.component.scss'],
  standalone: true
})
export class IconComponent implements OnInit {
  @Input() iconName: string;

  constructor() {}

  ngOnInit() {
    this.iconName = 'assets/icon/' + this.iconName;
  }
}
