import {Component, OnInit} from '@angular/core';
import {ApiService} from "src/app/core/services/api.service";
import {StorageService} from "src/app/shared/services/storage.service";

@Component({
  selector: 'app-pause-active-modules',
  templateUrl: './pause-active-modules.component.html',
  styleUrls: ['./pause-active-modules.component.scss'],
})
export class PauseActiveModulesComponent  implements OnInit {
  button: string = 'Szüneteltetem a gyakorlást';
  isSuspended: boolean | null = null;

  constructor(
    private apiService: ApiService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.init();
  }

  pause() {
    this.apiService.post('/reminders/set_suspension', {
      "n_reminders_suspended": true
    }).subscribe((response) => {
      this.storageService.set('isSuspended', '1');
      this.init();
    });
  }
  unsuspend() {
    this.apiService.post('/reminders/set_suspension', {
      "n_reminders_suspended": false
    }).subscribe((response) => {
      this.storageService.remove('isSuspended');
      this.init();
    });
  }

  suspendOrUnsuspend() {
    if (this.isSuspended) {
      this.unsuspend();
    } else {
      this.pause();
    }
  }

  private init() {
    this.isSuspended = this.storageService.get('isSuspended') === '1';
    this.button = this.isSuspended ? 'Folytatom' : 'Szüneteltetem a gyakorlást';
  }
}
