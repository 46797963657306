import {
  Injectable,
  isDevMode,
  NgModule,
} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {
  PreloadAllModules,
  Router,
  RouteReuseStrategy,
  RouterModule,
  RouterStateSnapshot,
  Routes,
  TitleStrategy,
} from "@angular/router";
import {IonicRouteStrategy} from "@ionic/angular";
import {environment} from "~/src/environments/environment";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/pages/dashboard',
  },
  {
    path: 'auth',
    title: 'Hitelesítés',
    loadChildren: () => import('./core/modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'pages',
    title: 'Bejelentkezéshez kötött',
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesModule),
  },
];

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {

  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    const sitename : string = environment.application.name;

    if(title !== undefined) {
      this.title.setTitle(`${title} - ${sitename}`);
    }
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: isDevMode(),
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
      initialNavigation: "enabledBlocking",
      paramsInheritanceStrategy: "always",
    }),
  ],
  exports: [RouterModule],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
  ],
})
export class AppRoutingModule {

  // Diagnostic only: inspect router configuration
  constructor(router: Router) {

    if(isDevMode()) {
      // Use a custom replacer to display function names in the route configs
      const replacer = (key : any, value : any) => (typeof value === 'function') ? value.name : value;

      console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
    }

  }
}
