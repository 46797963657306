import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {CoreModule} from "./core/core.module";
import {SplashScreen} from "@capacitor/splash-screen";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {environment} from "~/src/environments/environment";
import { AngularFireModule } from "@angular/fire/compat";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  COLLECTION_ENABLED,
  CONFIG,
  DEBUG_MODE,
  APP_NAME, AngularFireAnalytics,
} from '@angular/fire/compat/analytics';
import { AngularFirePerformanceModule, PerformanceMonitoringService } from '@angular/fire/compat/performance';
import {FcmService} from "~/src/app/core/services/firebase-cloud-messaging.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
      // animated: true
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase, {automaticDataCollectionEnabled: true}), // Initialize Firebase
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
  ],
  providers: [
    FcmService,
    AngularFireAnalytics,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: CONFIG,
      useValue: {
        send_page_view: true,
        allow_google_signals: true,
        allow_ad_personalization_signals: true,
        anonymize_ip: true
       }
     },
    { provide: COLLECTION_ENABLED, useValue: true },
    { provide: DEBUG_MODE, useFactory: isDevMode  },
    { provide: APP_NAME, useValue: environment.application.name },
    PerformanceMonitoringService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorHandlingInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: CommonHttpInterceptor,
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
  ) {
    SplashScreen.hide();
  }
}
