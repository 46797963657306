<ng-container *ngIf="stepNumber === 1">
  <div
    class="quiz-answer shadow-basic d-flex "
    *ngFor="let answer of answers; let index = index;"
    [ngClass]="{'selected:': answer.isSelected}"
    (click)="clickAnswer(answer)"
    style="background: {{ answerClickedColorSetting[answer.status].backgroundColor }}"
  >
    <div class="answer-title d-flex-center"
         style="color: {{ answerClickedColorSetting[answer.status].textColor }}"
    >
      {{ answer.title }}
    </div>
    <div class="answer-countdown " *ngIf="answer.isSelected">
      <app-countdown
        [duration]="3"
        [fontSize]="12"
        [color]="answerClickedColorSetting[answer.status].textColor"
        [paused]="false"
        (countdownFinished)="answer.isSelected = false; handleCountdownFinished(answer)"
      ></app-countdown>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="stepNumber === 2">
  <div
    class="quiz-answer shadow-basic d-flex "
    style="background: {{ answerClickedColorSetting.goodAnswer.backgroundColor }}"
  >
    <div class="answer-title d-flex-center"
         style="color: {{ answerClickedColorSetting.goodAnswer.textColor }}"
    >
      {{ correctAnswer?.title }}
    </div>
  </div>
  <div *ngIf="isCorrect === false">
    <div
      class="quiz-answer shadow-basic d-flex "
      style="background: {{ answerClickedColorSetting.wrongAnswer.backgroundColor }}"
    >
      <div class="answer-title d-flex-center"
           style="color: {{ answerClickedColorSetting.wrongAnswer.textColor }}"
      >
        {{ selectedAnswer?.title }}
      </div>
    </div>
  </div>

  <ng-template #incorrectAnswer>
    <div class="correction">{{ successAnswerText }}</div>
  </ng-template>

  <div *ngIf="isCorrect === true else incorrectAnswer" class="correction">{{ answerCorrectionText }}</div>

  <div class="quiz-actions">
    <div class="separator"></div>
    <app-button (click)="next()">
      Következő
    </app-button>
  </div>
</ng-container>
