<div class="active-task-container">
  <div class="active-task-header"
       [ngClass]="{'primary': color === 'primary', 'secondary': color === 'secondary', 'hasRightBubble' : hasRightBubble}"
       [routerLink]="link"
  >
    <app-icon *ngIf="hasRightBubble" class="pipe-icon" [iconName]="'pipe.svg'"></app-icon>
    <span [ngClass]="{'hasRightBubble' : hasRightBubble}" class="active-task-header-title">{{ title }}</span>
    <div *ngIf="hasRightBubble" class="bubble-inner__item right-bubble">

      <div class="counter-bubble">
        <span class="counter">{{from}}/{{to}}</span>
      </div>
    </div>


  </div>
  <div class="active-task-content shadow-basic" [ngClass]="{'no-padding': noPadding}">
    <ng-content></ng-content>
  </div>
</div>
