<img
  (click)="openContextMenu($event)" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  *ngIf="colorType !== 'dark'" class="s-30 rounded-menu" src="/assets/icon/rounded-menu-icon.svg" alt="rounded-menu-button"
/>

<svg
  (click)="openContextMenu($event, 'right')"
  cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  *ngIf="colorType === 'dark'" class="s-30 rounded-menu" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="30" height="30" rx="15" fill="#D0D1D6"/>
  <rect width="3" height="3" rx="1.5" transform="matrix(1 0 0 -1 8 17)" fill="#182648"/>
  <rect width="3" height="3" rx="1.5" transform="matrix(1 0 0 -1 13.5 17)" fill="#182648"/>
  <rect width="3" height="3" rx="1.5" transform="matrix(1 0 0 -1 19 17)" fill="#182648"/>
</svg>


<ng-template
  *ngIf="trigger && isModalShown"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isModalShown"
  [cdkConnectedOverlayPositions]="positions"
>
  <app-modal
    *ngIf="type === 'context-menu'"
    [type]="'context-menu'"
    class="context-menu-modal"
    (closeEmitter)="close()"
    [isHiddenCloseButton]="true"
  >
    <div id="contextMenu" class="modal-content context-menu-modal-content">
      <ul class="menu-list">
        <li (click)="share()">
          <app-icon [iconName]="'share.svg'"></app-icon>
          <span class="menu-text">Megosztom</span>
        </li>
        <li (click)="doItLater()" *ngIf="!isPostponed">
          <app-icon [iconName]="'do-it-later.svg'"></app-icon>
          <span class="menu-text">Későbbre halasztom</span>
        </li>
        <li (click)="continue()" *ngIf="isPostponed">
          <app-icon [iconName]="'do-it-later.svg'"></app-icon>
          <span class="menu-text">Folytatom</span>
        </li>
        <li (click)="iKnow()" *ngIf="!isFinishedOrDone">
          <app-icon [iconName]="'heavy-mark-star.svg'"></app-icon>
          <span class="menu-text">Ezt a {{ contentType === 'practise_session' ? 'feladatot' : 'kurzust' }} már tudom</span>
        </li>
        <li (click)="feedback()"  *ngIf="false">
          <app-icon [iconName]="'paper-airplane.svg'"></app-icon>
          <span class="menu-text">Visszajelzés küldése</span>
        </li>
        <li (click)="toggleFavorite()">
          <div class="favorite-icon-container">
            <app-icon *ngIf="isFavorite" [iconName]="'star-full.svg'"></app-icon>
            <app-icon *ngIf="!isFavorite" [iconName]="'star-empty.svg'"></app-icon>
          </div>
          <span class="menu-text">{{ isFavorite ? 'Kedvencekből törlöm' : 'Kedvencek közé adom' }}</span>
        </li>
      </ul>
    </div>
  </app-modal>
  <app-modal
    *ngIf="type === 'postpone-popup'"
    class="info-modal"
    [type]="'info-popup'"
    (closeEmitter)="close()"
  >
    <div class="modal-content-info">
      <h2 class="text-bold">Halasztás</h2>
      <p>
        Valós okkal halasztod, vagy halogatod a feladatot? Ha utóbbi, akkor gondold át még egyszer. Hidd el, megéri az extra energia-befektetést!
      </p>
      <div class="mt-10">
        <app-button (click)="close()">Mégis megpróbálom</app-button>
      </div>
      <div class="mt-10">
        <app-button [color]="'secondary'" (click)="doItLaterSubmit()">Későbbre halasztom</app-button>
      </div>
    </div>
  </app-modal>
  <app-modal
    *ngIf="type === 'continue-popup'"
    class="info-modal"
    [type]="'info-popup'"
    (closeEmitter)="close()"
  >
    <div class="modal-content-info">
      <h2 class="text-bold">Folytatás</h2>
      <p>
        Folytatni szeretnéd a feladatot? Ha úgy érzed, hogy folytatni szeretnéd, akkor válaszd ezt az opciót.
      </p>
      <div class="mt-10">
        <app-button (click)="close()">Mégse</app-button>
      </div>
      <div class="mt-10">
        <app-button [color]="'secondary'" (click)="continueSubmit()">Folytatom</app-button>
      </div>
    </div>
  </app-modal>
  <app-modal
    *ngIf="type === 'iknow-popup'"
    class="info-modal"
    [type]="'info-popup'"
    (closeEmitter)="close()"
  >
    <div class="modal-content-info">
      <h2 class="text-bold">Biztosan menni fog, jól begyakoroltad?</h2>
      <p>
        Ha úgy érzed, hogy már tudod a feladatot, akkor válaszd ezt az opciót.
      </p>
      <div class="mt-10">
        <app-button (click)="close()">Inkább még maradok gyakorolni.</app-button>
      </div>
      <div class="mt-10">
        <app-button [color]="'secondary'" (click)="iKnowSubmit()">Igen, már jól megy, irány a következő feladat!</app-button>
      </div>
    </div>
  </app-modal>
  <app-modal
    *ngIf="type === 'rate'"
    class="info-modal"
    [type]="'info-popup'"
    (closeEmitter)="close()"
  >
    <div class="modal-content-info">
      <h2 class="text-bold">Értékeld, hogy haladsz a feladattal, mennyire sikerül a gyakorlatban megvalósítanod.</h2>
      <p>
        Jelölj be magasabb számot, ha úgy érzed jobban megy.
        Nem baj, ha még nem az igazi! Ha 3-asnál kisebb számot adsz, akkor próbálj többet gyakorolni!
      </p>

      <div class="rate-container">
        <div class="rate-item" [ngClass]="{'active': rate.isSelected}" *ngFor="let rate of rates; let i = index" (click)="setRate(i)">
          <div class="rate-value" >{{rate.value}}</div>
        </div>
        <div *ngIf="isShowRateTooltip" class="tooltip">
          <div class="tooltip-content">
            <p>
              Kattints a megfelelő számra, hogy értékeld a feladatot!
            </p>
          </div>
        </div>
        <app-icon (click)="toggleRateTooltip()" [iconName]="'question-rounded.svg'" class="rate-icon"></app-icon>
      </div>
      <div class="separator"></div>

      <h2 class="text-bold">Saját jegyzet (opcionális)</h2>
      <textarea [(ngModel)]="rateNote" placeholder="Érdemes lejegyzetelni a tapasztalatid, hogy visszanézhesd"></textarea>

      <div class="mt-10">
        <app-button (click)="rate()">OK</app-button>
      </div>
    </div>
  </app-modal>
  <app-modal
    *ngIf="type === 'remove-from-favorite'"
    class="info-modal"
    [type]="'info-popup'"
    (closeEmitter)="close()"
  >
    <div class="modal-content-info">
      <h2 class="text-bold">Biztos eltávolítod a kedvencek közül?</h2>
      <p>
        Ha eltávolítod a kedvencek közül, akkor a feladat már nem lesz elérhető a kedvencek között.
      </p>
      <div class="mt-10">
        <app-button (click)="close()">Mégse</app-button>
      </div>
      <div class="mt-10">
        <app-button [color]="'secondary'" (click)="removeFromFavorite()">Eltávolítom a kedvencek közül</app-button>
      </div>
    </div>
  </app-modal>
</ng-template>
