<img
  (click)="isNotificationsModalShown = true" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  class="s-30 notification-icon" src="/assets/icon/notification.svg" alt="notifications button" />

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isNotificationsModalShown"
>
  <app-modal
    [type]="'notification-button'"
    class="notification-modal-container"
    [isTransparent]="true"
    (closeEmitter)="isNotificationsModalShown = !isNotificationsModalShown"
  >
    <div class="modal-content">
      <app-notification-settings
        (closeEmitter)="isNotificationsModalShown = !isNotificationsModalShown"
      ></app-notification-settings>
    </div>
  </app-modal>
</ng-template>
