export const ENVIRONMENT_DEFAULTS = {
  isDemoMode: false,
  locales: [
    { lang: 'en_US', currency: 'USD', value: 'en', displayName: 'English', displayLong: 'English (United States)' },
    { lang: 'de_DE', currency: 'EUR', value: 'de', displayName: 'German', displayLong: 'German (Germany)' },
    { lang: 'fr_FR', currency: 'EUR', value: 'fr', displayName: 'French', displayLong: 'French (France)' },
  ],
  firebase: {
    apiKey: "AIzaSyDxYrAXQ3WGfZ0czIYIPsnKTHh3wa1L5CA",
    authDomain: "apparently-9441d.firebaseapp.com",
    projectId: "apparently-9441d",
    storageBucket: "apparently-9441d.appspot.com",
    messagingSenderId: "39857923809",
    appId: "1:39857923809:web:81a53b33ff9901113ab9a0",
    measurementId: "G-HC0HY7ZW03",
  },
  cookieConsentV3Config: {
    revision: 1,

    categories: {
      necessary: {
        enabled: true,
        readOnly: true
      },

      analytics: {
        enabled: false,
        readOnly: false,
      },

      ads: {
        enabled: false,
        readOnly: false,
      }
    },

    language: {
      default: 'hu',
      translations: {
        hu: {
          consentModal: {
            title: 'Sütik használata',
            description: 'Az applikáció sütiket használ a felhasználói élmény javítása érdekében. Az alábbiakban kiválaszthatja, hogy mely sütiket szeretné elfogadni.',
            acceptAllBtn: 'Mind elfogadása',
            acceptNecessaryBtn: 'Minden elutasítása',
            showPreferencesBtn: 'Egyéni beállítások megjelenítése'
          },
          preferencesModal: {
            title: 'Sütikezelési preferenciák',
            acceptAllBtn: 'Mind elfogadása',
            acceptNecessaryBtn: 'Minden elutasítása',
            savePreferencesBtn: 'Jelenlegi kiválasztás elfogadása',
            closeIconLabel: 'Felugró bezárása',
            sections: [
              {
                title: 'Kizárólag szükséges sütik',
                description: 'Ezek a sütik elengedhetetlenek a weboldal megfelelő működéséhez, és nem lehet őket letiltani.',
                linkedCategory: 'necessary'  // ez a mező egy kapcsolót generál a 'szükséges' kategóriához
              },
              {
                title: 'Teljesítmény és analitika',
                description: 'Ezek a sütik információkat gyűjtenek arról, hogyan használja weboldalunkat. Az összes adat anonimizált, és nem használható felhasználó azonosítására.',
                linkedCategory: 'analytics'
              },
              {
                title: 'Hirdetés és ajánlatok',
                description: 'Ezek a sütik információkat gyűjtenek ahhoz, hogy személyre szóló hirdetéseket és ajánlatok kínálhassunk.',
                linkedCategory: 'ads'
              }
            ]
          }
        }
      }
    }
  }
};
