<div class="w-100">
  <h3 class="blue-dark font-medium-size fw-700 uppercase">Hírek</h3>

  <ng-container *ngIf="newslist">
    <div class="mt-10 news-container bg-white br-8" *ngFor="let news of newslist">
      <a [href]="news.url" target="_blank" style="text-decoration: none;">
        <p class="dark-orange fw-400 font-size my-2">{{news.date}}</p>
        <p class="blue-dark fw-700 font-medium-size my-2">{{news.title}}</p>
        <p class="blue-dark fw-400 font-size my-2" [innerHTML]="news.message"></p>
      </a>
    </div>
  </ng-container>
</div>
