import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-swipe-paginator',
  templateUrl: './swipe-paginator.component.html',
  styleUrls: ['./swipe-paginator.component.scss'],
})
export class SwipePaginatorComponent  implements OnInit {
  @Input() numberOfElement = 0;
  @Input() activeElementIndex = 0;
  @Output() clickEmitter: EventEmitter<number> = new EventEmitter<number>();

  public elements: any[] = [];
  public numberOfChangeDesignToMiniViewForLotOfElement = 16;

  constructor() {}

  ngOnInit() {
    this.elements = new Array(this.numberOfElement);
  }

  pageClick(i: number) {
    this.clickEmitter.emit(i);
  }
}
