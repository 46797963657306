import {ENVIRONMENT_DEFAULTS} from '~/src/environments/environment-defaults';

export const environment = {
  ...ENVIRONMENT_DEFAULTS,
  production: false,
  application:
    {
      name: 'apparently',
      domain: 'app.apparently.hungaropixel.hu',
      protocol: 'https',
    },
  api_url: 'https://admin.apparently.hungaropixel.hu/api',
  backendBaseUrl: 'https://admin.apparently.hungaropixel.hu',
  ws: 'ws://admin.apparently.hungaropixel.hu:1080/rooms',
  defaultChatRoomId: 1,
  isWebsocketProject: false
};
