import { Injectable } from '@angular/core';
import {ApiService} from "~/src/app/core/services/api.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ActivityLogService {
  constructor(private apiService: ApiService) { }

  remindersOpen(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'reminders--open',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
  courseStarted(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'course--started',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
  courseFinished(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'course--finished',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
  coursePostponed(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'course--postponed',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
  practiceSessionFinished(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--finished',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
  practiceSessionPostponed(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--postponed',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  practiceSessionContinue(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--continue',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  courseContinue(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'course--continue',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  practiceSessionVideoCompleted(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--video--completed',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  lessionsVideoCompleted(lessonId: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'lessons--video--completed',
          "data": {id: lessonId}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  hintsOpen(hintId: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'hints--open',
          "data": {id: hintId}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
  genericLog(data: any) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'generic',
          "data": data
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  practiceSessionVideoOpened(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--video--opened',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  lessonsVideoOpened(lessonId: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'lessons--video--opened',
          "data": {id: lessonId}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  practiceSessionQuizCompletedFailed(id: number, results: {question_id: number; answer_idx: number;}[]) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--quiz--completed--failed',
          "data": {id},
          results
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  practiceSessionQuizCompletedSuccessful(id: number, results: {question_id: number; answer_idx: number;}[]) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--quiz--completed--successful',
          "data": {id},
          results
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  lessonsQuizCompletedFailed(lessonId: number, results: {question_id: number; answer_idx: number;}[]) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'lessons--quiz--completed--failed',
          "data": {
            "id": lessonId,
            "results": results
          }
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  lessonsQuizCompletedSuccessful(lessonId: number, results: {question_id: number; answer_idx: number;}[]) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'lessons--quiz--completed--successful',
          "data": {
            "id": lessonId,
            "results": results
          }
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  practiceSessionCompleted(id: number) {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'practise-sessions--completed',
          "data": {id}
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }

  logLearningOnboardingCompleted() {
    return this.apiService
      .post<any>('/activity_log',
        {
          type: 'elearning--onboarding-completed',
        }
      )
      .pipe(map(data => {
        return data;
      }));
  }
}
