import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-widget-container',
  templateUrl: './widget-container.component.html',
  styleUrls: ['./widget-container.component.scss'],
})
export class WidgetContainerComponent  implements OnInit {

  @Input() color !: 'primary' | 'secondary' | string;
  @Input() title !: string;
  @Input() from !: number;
  @Input() to !: number;
  @Input() noPadding = false;
  @Input() hasRightBubble = false;
  @Input() link: string | null | undefined = null;

  constructor() { }

  ngOnInit() {}
}
