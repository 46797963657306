import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IconComponent} from "~/src/app/core/components/icon/icon.component";

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconComponent
  ]
})
export class ModalComponent {
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Input() isTransparent = false;
  @Input() isFullWidth = false;
  @Input() isVideoModal = false;
  @Input() isHiddenCloseButton = false;
  @Input() type: string;

  close(value: any): void {
    this.closeEmitter.emit(value);
  }
}
