import { Component, Input } from '@angular/core';
import {Errors} from "~/src/app/core/models";
import {isArray, isObject} from "lodash";

@Component({
  selector: 'app-list-errors',
  templateUrl: './list-errors.component.html'
})
export class ListErrorsComponent {
  formattedErrors: Array<string> = [];
  @Input()
  set errorStrings(errorList: any) {
    this.formattedErrors = errorList.map((err: any) => {
       if (isObject(err)) {
         const errorKeys = Object.keys(err)

        // @ts-ignore
         return err[errorKeys[0]];
       }
      return err;
    });
  }
  @Input()
  set errors(errorList: Errors) {
    this.formattedErrors = Object.keys(errorList.errors || {})
      .map(key => `${errorList.errors[key]}`);
  }

  get errorList() { return this.formattedErrors; }
}
