import {Component, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent  implements OnInit {
  isNotificationsModalShown = false;

  constructor() { }

  ngOnInit() {}

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.isNotificationsModalShown = false;
  }

}
