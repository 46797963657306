import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-percent-bar',
  templateUrl: './percent-bar.component.html',
  styleUrls: ['./percent-bar.component.scss'],
})
export class PercentBarComponent implements OnInit {

  @Input() percent: number = 0;
  @Input({ required: true }) color !: string;
  @Input({ required: true }) backgroundColor !: string;

  constructor() {}

  ngOnInit() {
  }
}
