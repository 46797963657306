import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Question, QuizAnswer} from "src/app/shared/models/quizTypes";
import {ActivityLogService} from "src/app/shared/services/activitylog.service";

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent  implements OnInit {
  @Input() type: 'list' | 'yesno' | 'select' | 'draganddrop' | 'text' | undefined = 'draganddrop';
  @Input() quiz: any;
  @Input() quizType: 'practiceSession' | 'lession' | 'practiceSessionVideo' = 'lession';
  @Input() parentData: any;
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() isSuccessEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() isWrongEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() isFilledEmitter: EventEmitter<any> = new EventEmitter<any>();
  question = '';
  isCorrect = false;
  answers: QuizAnswer[] = [];
  answerClickedColorSetting = {
    default: {
      backgroundColor: 'white',
      textColor: '#182648',
    },
    selected: {
      backgroundColor: '#182648',
      textColor: 'white',
    },
    wrongAnswer: {
      backgroundColor: '#C95B32',
      textColor: 'white',
    },
    goodAnswer: {
      backgroundColor: '#7AC2B1',
      textColor: '#182648',
    }
  };
  quizTitle = '';
  quizSubTitle = '';
  quizName= '';
  from = 1;
  to = 1;
  questions: Question[] = [];
  answerList:  QuizAnswer[][] = [];
  percentage = 0;
  isInProgress = true;
  percentageWhenPassed = 66;

  private howManyGoodAnswers = 0;

  constructor(
    private activityLogService: ActivityLogService
  ) {}

  ngOnInit() {
    const answerList: any[] = [];

    // @ts-ignore
    if (this.quiz) {

      this.quizName = this.quiz.title;
      this.question = this.quiz.question;
      this.isCorrect = this.quiz.isCorrect;
      this.quizTitle = this.parentData.title;
      this.quizSubTitle = '';
      this.to = this.quiz.questions.length;

      this.quiz.questions.map((question: Question) => {
        if (question.type !== 'yesno') {
          const answers = [];
          if (question.answer1 !== null && question.answer1 !== '') {
            answers.push({
              id: 1,
              questionId: question.id,
              quizId: question.quiz_id,
              title: question.answer1,
              wrongAnswerDesc: question.answer1_explanation,
              status: 'default',
              isCorrect: false,
              isSelected: false,
              isAnswered: false,
              question: question.question,
              minCaracterNumber: question.answer_min_length,
              type: question.type
            });
          }
          if (question.answer2 !== null && question.answer2 !== '') {
            answers.push({
              id: 2,
              questionId: question.id,
              quizId: question.quiz_id,
              title: question.answer2,
              wrongAnswerDesc: question.answer2_explanation,
              status: 'default',
              isCorrect: false,
              isSelected: false,
              isAnswered: false,
              question: question.question,
              minCaracterNumber: question.answer_min_length,
              type: question.type
            });
          }
          // Repeat this pattern for answer3, answer4, and answer5

          answers.push({
            id: 3,
            questionId: question.id,
            quizId: question.quiz_id,
            title: question.correct_answer,
            correctAnswer: question.correct_answer_explanation,
            status: 'default',
            isCorrect: true,
            isSelected: false,
            isAnswered: false,
            question: question.question,
            minCaracterNumber: question.answer_min_length,
            type: question.type
          });
          answerList.push(this.shuffleArray(answers));
        } else {
          // For 'yesno' type questions, only include the question and correct answer
          answerList.push(this.shuffleArray([
            {
              id: 4,
              questionId: question.id,
              quizId: question.quiz_id,
              title: question.answer1,
              wrongAnswerDesc: question.answer1_explanation,
              status: 'default',
              isCorrect: false,
              isSelected: false,
              isAnswered: false,
              question: question.question,
              minCaracterNumber: question.answer_min_length,
              type: question.type
            },
            {
              id: 5,
              questionId: question.id,
              quizId: question.quiz_id,
              title: question.correct_answer,
              correctAnswer: question.correct_answer_explanation,
              status: 'default',
              isCorrect: true,
              isSelected: false,
              isAnswered: false,
              question: question.question,
              minCaracterNumber: question.answer_min_length,
              type: question.type
            }
          ]));
        }
      });

      this.answerList = answerList;

      this.answers = this.answerList[0];
      this.question = this.answerList[0][0].question;
      this.isCorrect = this.answerList[0][0].isCorrect;

      this.handleType();
    }
  }

  enlargeFrom(isCorrect = false) {
    const isCorrectAnswer = isCorrect;

    if (this.from < this.to) {


      if (isCorrectAnswer) {
        this.howManyGoodAnswers++;
      }

      this.from++;
      const index = this.from - 1;
      // todo set isSelected = true
      this.answers = this.answerList[index];
      this.question = this.answerList[index][0].question;
      this.isCorrect = this.answerList[index][0].isCorrect;
      this.handleType();

      return;
    }

    if (isCorrectAnswer) {
      this.howManyGoodAnswers++;
    }

    const percentage = this.howManyGoodAnswers ? (this.howManyGoodAnswers / this.from) * 100 : 0;
    this.percentage = percentage;
    this.isInProgress = false;
    this.isCorrect = this.percentage >= this.percentageWhenPassed;
    if (this.isCorrect) {
      this.handleLogCompleted().subscribe(() => {
        this.isSuccessEmitter.emit();
      })
    } else {
      this.handleLogFailed();
      this.isWrongEmitter.emit();
    }
  }

  restartQuiz() {
    this.from = 0;
    this.howManyGoodAnswers = 0;
    this.percentage = 0;
    this.isInProgress = true;
    this.enlargeFrom();
  }

  private handleType() {
    const index = this.from - 1;

    switch (this.answerList[index][0].type) {
      case 'yesno':
        this.type = 'yesno';
        break;
      case 'selector':
        this.type = 'list';
        break;
      case 'dropdown':
        this.type = 'select';
        break;
      case 'custom_text':
        this.type = 'text';
        break;
      case 'drag_n_drop':
        this.type = 'draganddrop';
        break;
      default:
        this.type = 'list';
        break;
    }
  }
  private shuffleArray<T>(array: T[]): T[] {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  };

  close() {
    this.closeEmitter.emit();
    this.isFilledEmitter.emit();
  }

  private handleLogCompleted() {
    const payload = this.answerList.map((answers, index) => {
      return {
        question_id: answers[0].questionId,
        answer_idx: answers.findIndex(answer => answer.isAnswered)
      };
    });
    if (this.quizType === 'practiceSession' || this.quizType === 'practiceSessionVideo') {
      return this.activityLogService.practiceSessionQuizCompletedSuccessful(this.quiz.id, payload);
    } else {
      return this.activityLogService.lessonsQuizCompletedSuccessful(this.quiz.id, payload);
    }
  }

  private handleLogFailed() {
    const payload = this.answerList.map((answers, index) => {
      return {
        question_id: answers[0].questionId,
        answer_idx: answers.findIndex(answer => answer.isAnswered)
      };
    });

    if (this.quizType === 'practiceSession' || this.quizType === 'practiceSessionVideo') {
      return this.activityLogService.practiceSessionQuizCompletedFailed(this.quiz.id, payload).subscribe();
    } else {
      return this.activityLogService.lessonsQuizCompletedFailed(this.quiz.id, payload).subscribe();
    }
  }
}
