import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {lastValueFrom} from "rxjs";
import {VideoJsOptions} from "src/app/shared/components/video-js/video-js.component";
import {ActivityLogService} from "src/app/shared/services/activitylog.service";

export interface Caption {
    url: string;
    lang?: string;
    label?: string;
}
export interface Video {
    url: string;
    posterUrl?: string,
    label?: string;
}

const defaultPosterUrl =  'assets/video-poster-default.jpg';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent  implements OnInit {
  @Input() url ?: string | undefined | null;
  @Input() posterUrl : string | undefined | null = defaultPosterUrl;
  @Input() posterSrcset ?: string;
  @Input() posterLoadingType : 'lazy' | 'eager' | 'auto' = 'auto';
  @Input() posterWidth ?: number;
  @Input() posterHeight ?: number;
  @Input() isVideoModalShown : boolean = false;
  @Input() isBordered : boolean = false;
  @Input() contentType ?: 'practise_session' | 'lession' | null = null;
  @Input() contentId ?: number | undefined | null = null;
  @Input() challenge: any;
  @Output() videoIsViewedEvent = new EventEmitter<boolean>();
  @Output() isSubscriptionLimitedEmitter = new EventEmitter<boolean>();
  @Output() isLessionsIsNotCompletedEmitter = new EventEmitter<boolean>();

  public options: VideoJsOptions | undefined | null;
  public isVideo: boolean = false;

  private isSubscriptionLimited = false;

  constructor(
    private activityLogService: ActivityLogService,
  ) { }

  async ngOnInit() {
    this.isVideo = this.url ? this.url.indexOf('.mp4') > -1 : false;
    this.options = {
          fluid: true,
          aspectRatio: '9:16',
          autoplay: true,
          disablePictureInPicture: true,
          sources: [
              {
                  src: this.url || '',
                  type: 'video/mp4',
              }
          ],
          controlBar: {
            pictureInPictureToggle: false // Ez a beállítás letiltja a Picture-in-Picture gombot a vezérlőből
          },
          enableDocumentPictureInPicture: false,
          playsinline: true,
      };

    if (this.isVideoModalShown) {
      await this.handleVideoOpenLog();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.isVideoModalShown = false;
  }

  videoIsViewedHandler($event: boolean) {
    this.videoIsViewedEvent.emit($event);
    if (this.contentType === 'practise_session' && this.contentId) {
      this.activityLogService.practiceSessionVideoCompleted(this.contentId).subscribe();
    } else if (this.contentType === 'lession' && this.contentId) {
      this.activityLogService.lessionsVideoCompleted(this.contentId).subscribe();
    }
  }
  async open() {
    if (this.challenge && !this.challenge.progression?.is_lessons_completed) {
      this.isLessionsIsNotCompletedEmitter.emit();
      return;
    }
    if (this.isSubscriptionLimited) {
      this.isSubscriptionLimitedEmitter.emit(true);
      return;
    }
    await this.handleVideoOpenLog();

    if (!this.isSubscriptionLimited) {
      this.isVideoModalShown = true;
    }
  }

  private async handleVideoOpenLog() {
    let log : any;

    if (this.contentType === 'practise_session' && this.contentId) {
      log = lastValueFrom(this.activityLogService.practiceSessionVideoOpened(this.contentId));
    } else if (this.contentType === 'lession' && this.contentId) {
      log = lastValueFrom(this.activityLogService.lessonsVideoOpened(this.contentId));
    }

    if (log && log.status === 'subscription_limited') {
      this.isSubscriptionLimited = true;
      this.isSubscriptionLimitedEmitter.emit(true);
    }

    return log;
  }
}
