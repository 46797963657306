<div class="custom-select-container">
  <label class="custom-select-label">
    <select class="custom-select" [ngModel]="selectedOption" (change)="onOptionChange($event)">
      <option disabled [value]="null" selected>Válassz..</option>
      <option *ngFor="let option of options" [value]="option.id">{{ option.title }}</option>
    </select>
    <div class="custom-select-icon" (click)="toggleSelect($event)" tappable>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
    </div>
  </label>
</div>
