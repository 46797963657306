import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { IonCheckbox, IonRadio } from '@ionic/angular';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent {
  @Input() formGroup!: FormGroup;

  constructor() {}

  getControlNames(formGroup: FormGroup): string[] {
    return Object.keys(formGroup.controls);
  }

  getControlType(control: any): any {
    if (control instanceof FormGroup) {
      return 'group';
    } else if (control instanceof FormArray) {
      return 'array';
    } else {
      return control instanceof IonCheckbox ? 'checkbox' : control instanceof IonRadio ? 'radio' : 'input';
    }
  }

  isControlType(controlName: string, type: string): boolean {
    // @ts-ignore
    return this.getControlType(this.formGroup.get(controlName)) === type;
  }

  addControl(controlName: string): void {
    const control = this.formGroup.get(controlName) as FormArray;
    control.push(new FormGroup({}));
  }

  onSubmit(): void {
    if (this.formGroup.valid) {
      console.log('Form submitted:', this.formGroup.value);
    } else {
      console.log('Form is invalid.');
    }
  }
}
