import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-horisontal-progression-indicator',
  templateUrl: './horisontal-progression-indicator.component.html',
  styleUrls: ['./horisontal-progression-indicator.component.scss'],
})
export class HorisontalProgressionIndicatorComponent  implements OnInit {
  @Input() days ?: any = [];
  @Input() practiceSession : any;

  constructor() { }

  ngOnInit() {
    const practiceSession = this.practiceSession;
    const quizes = practiceSession.practise_session_quizes;

    this.days = quizes.map((quiz: any) => {
      let status = 'empty';
      if (quiz.progression.status === 'finished' || quiz.progression.status === 'completed_successful') {
        status = 'success';
      } else if (quiz.progression.status === 'completed_failed') {
        status = 'failed';
      }
      return {
        name: quiz.title,
        status: status // success, failed, empty
      };
    });
  }

}
