<ng-container *ngIf="isVideo === true && posterSrcset">
  <div [ngClass]="{'bordered': isBordered}" class="video-poster-container" (click)="open()" cdkOverlayOrigin>
    <app-icon class="play-icon" iconName="video-play-button.svg"></app-icon>
    <img
      [src]="posterUrl"
      [srcset]="posterSrcset"
      class="clickable"
      alt="Video poster"
      [loading]="posterLoadingType"
      [attr.width]="posterWidth"
      [attr.height]="posterHeight"
    >
  </div>
</ng-container>

<ng-container *ngIf="isVideo === true && ! posterSrcset">
  <div [ngClass]="{'bordered': isBordered}" class="video-poster-container" (click)="open()" cdkOverlayOrigin>
    <app-icon class="play-icon" iconName="video-play-button.svg"></app-icon>
    <img
      [src]="posterUrl"
      class="clickable"
      alt="Video poster"
      [loading]="posterLoadingType"
      [attr.width]="posterWidth"
      [attr.height]="posterHeight"
    >
  </div>
</ng-container>

<ng-container *ngIf="isVideo === false && url && url !== '' && ! posterSrcset">
  <img [src]="url" alt="thumbnail" [loading]="posterLoadingType" [attr.width]="posterWidth" [attr.height]="posterHeight">
</ng-container>

<ng-container *ngIf="isVideo === false && url && url !== ''">
  <img [src]="url" [srcset]="posterSrcset" alt="thumbnail" [loading]="posterLoadingType" [attr.width]="posterWidth" [attr.height]="posterHeight">
</ng-container>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOpen]="isVideoModalShown"
>
  <app-modal
    [type]="'video'"
    [isTransparent]="false"
    [isVideoModal]="true"
    (closeEmitter)="isVideoModalShown = !isVideoModalShown"
  >
    <div class="modal-content video-modal-content" style="height: 80vh">
      <app-vjs-player
        class="vjs-player"
        [options]="options"
        [posterUrl]="posterUrl"
        (videoIsViewedEvent)="videoIsViewedHandler($event)"
      >
      </app-vjs-player>
    </div>
  </app-modal>
</ng-template>
