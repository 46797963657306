import {Component, Input, OnInit} from '@angular/core';
import { SuccessTaskDto } from '../../models/successTasksDto';

@Component({
  selector: 'app-task-success',
  templateUrl: './task-success.component.html',
  styleUrls: ['./task-success.component.scss'],
})
export class TaskSuccessComponent  implements OnInit {
  @Input() dashboardCompetencyProgression: any;
  percent: number = 70;
  title: string = 'Eddig fejlesztett kompentenciák';
  successTasks: SuccessTaskDto [] = []

  constructor() {}

  ngOnInit() {
    this.successTasks = this.dashboardCompetencyProgression.map((task: any) => {
      return {
        task: task.title,
        color: task.foreground_color,
        backgroundColor: task.background_color,
        percent: task.progression.completion_ratio
      }
    });
  }

}
