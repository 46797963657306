<div class="w-100" *ngIf="!onlySearchInput">
  <h3 class="blue-dark font-medium-size fw-700 uppercase">Kihívás kereső</h3>

  <div class="search-container bg-white d-flex-column gap-10 br-8 px-20 py-20">
    <p class="blue-dark fw-700 font-medium-size my-2">
      Belekezdenél egy újba?
    </p>
    <div class="input-wrapper">
      <input
        [ngModel]="searchQuery"
        (ngModelChange)="this.searchQueryUpdate.next($event)"
        class="w-100 px-10 bg-grey br-8" type="text"
        placeholder="Keresés kulcsszavakra">
      <img class="s-20" src="/assets/icon/search.svg" alt="">
    </div>
    <app-button class="text-center" color="orange" (click)="searchNavigation()">
      {{ searchQuery === '' ? 'Mutasd az összes kihívást!' : 'Mutasd a találati listát!' }}
    </app-button>
  </div>
</div>
<div class="w-100" *ngIf="onlySearchInput">
  <div class="search-container bg-white d-flex-column gap-10 br-8 px-20 py-20">
    <div class="input-wrapper">
      <input
        [ngModel]="searchQuery"
        (ngModelChange)="this.searchQueryUpdate.next($event)"
        class="w-100 px-10 bg-grey br-8" type="text"
        placeholder="Keresés kulcsszavakra">
      <img class="s-20" src="/assets/icon/search.svg" alt="">
    </div>
  </div>
</div>
