import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, EventEmitter,
  HostListener,
  Input,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {FavoriteService} from "~/src/app/shared/services/favorite.service";
import {ActivatedRoute} from "@angular/router";
import {Share} from "@capacitor/share";
import {ActivityLogService} from "~/src/app/shared/services/activitylog.service";
import {environment} from "~/src/environments/environment";
import {CdkOverlayOrigin, ConnectedPosition} from "@angular/cdk/overlay";

export type ContentType = 'practise_session' | 'course';

@Component({
  selector: 'app-menu-button',
  templateUrl: './menu-button.component.html',
  styleUrls: ['./menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuButtonComponent  implements OnInit, AfterViewInit {
  @ViewChild('trigger') trigger:  CdkOverlayOrigin;
  @Input() colorType: 'dark' | null = null;
  @Input() isFavorite = false;
  @Input() contentType: ContentType = 'course';
  @Input() item: any;
  @Output() clickOnIKnowEmitter: EventEmitter<{id: number; contentType: ContentType}> = new EventEmitter<{id: number; contentType: ContentType}>();
  @Output() doItLaterEmitter: EventEmitter<{id: number; contentType: ContentType}> = new EventEmitter<{id: number; contentType: ContentType}>();
  @Output() continueEmitter: EventEmitter<{id: number; contentType: ContentType}> = new EventEmitter<{id: number; contentType: ContentType}>();

  isModalShown = false;
  private id: number;
  type: 'context-menu' | 'postpone-popup' | 'rate' | 'iknow-popup' | 'continue-popup' | 'remove-from-favorite' = 'context-menu';
  rateNote = '';
  rates = [
    {
      value: 1,
      text: 'Nagyon rossz',
      isSelected: false
    },
    {
      value: 2,
      text: 'Rossz',
      isSelected: false
    },
    {
      value: 3,
      text: 'Közepes',
      isSelected: false
    },
    {
      value: 4,
      text: 'Jó',
      isSelected: false
    }];
  selectedRate: any;
  isShowRateTooltip: boolean = false;
  isFinishedOrDone: boolean = false;
  isPostponed: boolean = false;
  removeFromFavoriteConfirmPopup: boolean;
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top'
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom'
    }
  ];

  constructor(
    private favoriteService: FavoriteService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private activityLogService: ActivityLogService
  ) { }

  ngOnInit() {
    this.isFinishedOrDone = this.item.progression.status === 'finished' || this.item.progression.status === 'completed';
    this.isPostponed = this.item.progression.status === 'postponed';
  }

  ngAfterViewInit() {
    this.handleId();
  }

  toggleFavorite() {
    if (!this.isFavorite) {
      this.favoriteService.setFavorite(this.contentType, this.id).subscribe((res: any) => {
        this.isFavorite = true;
        this.cdr.detectChanges();
      });
    } else {
      this.type = 'remove-from-favorite';
    }
  }

  removeFromFavorite() {
    this.favoriteService.getFavoritesByContentType(this.contentType).subscribe((res: any) => {
      const relevantFavorite = res.data.find((fav: any) => fav.content_id === this.id);

      this.favoriteService.deleteFavorite(relevantFavorite.id).subscribe((res: any) => {
        this.isFavorite = false;
        this.cdr.detectChanges();
      });
    });
    this.close();
  }

  open() {
    this.isModalShown = true
    this.favoriteService.getFavoritesByContentType(this.contentType).subscribe((res: any) => {
      if(res && res.length === 0 || !res) {
        this.isFavorite = false;
        return
      }
      const relevantFavorite = res.data.find((fav: any) => fav.content_id === this.id);

      this.isFavorite = !!relevantFavorite;
      this.cdr.detectChanges();
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.close();
  }

  private handleId() {
    const snapshot = this.activatedRoute.snapshot;
    const id = snapshot.paramMap.get('id') as string;

    this.id = +id;

    if (this.item) {
      this.id = this.item.id;
    }
  }

  async share() {
    const item = this.item;
    let title: string;
    let text: string;
    let practiceSessionUrlPart = '';
    let id: number;
    const domain = `${environment.application.protocol}://${environment.application.domain}`;

    this.close();

    if (this.contentType === 'practise_session') {
        title = 'Tekintsd meg ezt a feladatot.';
        id = item.course_id;
        practiceSessionUrlPart = '/' + id;
    } else {
        title = 'Tekintsd meg kurzusunkat.';
        id = item.id;
    }

    text = item.title;

    const {value } = await Share.canShare();

    if( ! value) {
      alert('Sajnos erről az eszközről nem tudod megosztani ezt a tartalmat. :_(');
      return;
    }

    await Share.share({
      title: title,
      text: text,
      url: `${domain}/pages/challenge/${id}${practiceSessionUrlPart}`,
      dialogTitle: 'Megosztás',
    });
  }

  doItLater() {
    this.type = 'postpone-popup';
  }
  continue() {
    this.type = 'continue-popup';
  }

  async continueSubmit() {
    let activityLogResponse;

    this.type = 'context-menu';
    if(this.contentType === 'practise_session') {
      activityLogResponse = await this.activityLogService.practiceSessionContinue(this.id).toPromise();
    } else {
      activityLogResponse = await this.activityLogService.courseContinue(this.id).toPromise();
    }
    this.close();
    const data =       {
      id: this.id,
      contentType: this.contentType,
      isSubscriptionLimited: activityLogResponse.status === 'subscription_limited'
    };

    this.continueEmitter.emit(data)
  }
  doItLaterSubmit() {
    this.type = 'context-menu';
    if(this.contentType === 'practise_session') {
      this.activityLogService.practiceSessionPostponed(this.id).subscribe();
    } else {
      this.activityLogService.coursePostponed(this.id).subscribe();
    }
    this.close();
    const data =       {
      id: this.id,
      contentType: this.contentType
    };

    this.doItLaterEmitter.emit(data)
  }

  iKnow() {
    this.type = 'iknow-popup';
  }
  async iKnowSubmit() {
    if (this.contentType === 'practise_session') {
      await this.activityLogService.practiceSessionFinished(this.id).toPromise();
    } else {
      await this.activityLogService.courseFinished(this.id).toPromise();
    }
    const data =       {
      id: this.id,
      contentType: this.contentType
    };
    this.clickOnIKnowEmitter.emit(data);
    this.close();
  }

  feedback() {
    this.type = 'rate';
  }
  close() {
    this.type = 'context-menu';
    this.isModalShown = false;
  }
  rate() {
    this.close();
  }
  toggleRateTooltip() {
    this.isShowRateTooltip = !this.isShowRateTooltip;
    setTimeout(() => {
      this.isShowRateTooltip = false;
      this.cdr.detectChanges();
    }, 3000);
  }
  setRate(i: number) {
    this.rates.forEach(rate => rate.isSelected = false);
    this.rates[i].isSelected = true;
    this.selectedRate = this.rates[i];
  }

  openContextMenu(event: MouseEvent, oriontation: 'left' | 'right' = 'left') {
    this.open();
    setTimeout(() => {
      const contextMenuRef = document.getElementById('contextMenu');

      if (contextMenuRef) {
        if (oriontation === 'left') {
          contextMenuRef.style.left = event.clientX + 'px';
        } else {
          contextMenuRef.style.left = event.clientX - 200 + 'px';
        }

        contextMenuRef.style.top = event.clientY + 'px';
      }
    })
  }
}
