import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ActivateYourAccountComponent} from "src/app/core/modules/landing-pages/activate-your-account/activate-your-account.component";


export const ROUTES: Routes = [
    {
        path: 'activate/:id',
        component: ActivateYourAccountComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES)],
    exports: [RouterModule]
})
export class LandingPagesRoutingModule {}
