import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-readiness-indicator',
  templateUrl: './readiness-indicator.component.html',
  styleUrls: ['./readiness-indicator.component.scss'],
})
export class ReadinessIndicatorComponent  implements OnInit {
  @Input() readyOf: number;
  @Input() readyFrom: number;
  @Input() status: 'inactive' | 'active' | 'completed' | 'finished' | 'postponed' | '' = '';

  constructor() { }

  ngOnInit() {}
}
