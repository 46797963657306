import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { AccountLevelComponent } from '~/src/app/shared/components/account-level/account-level.component';
import { TabsComponent } from '~/src/app/shared/components/tabs/tabs.component';
import { ActiveTasksComponent } from '~/src/app/shared/components/active-tasks/active-tasks.component';
import { TaskSuccessComponent } from '~/src/app/shared/components/task-success/task-success.component';
import { SearchComponent } from '~/src/app/shared/components/search/search.component';

import { PauseActiveModulesComponent } from '~/src/app/shared/components/pause-active-modules/pause-active-modules.component';
import { InputComponent } from '~/src/app/shared/components/form/input/input.component';
import { IonicModule } from '@ionic/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NewsListComponent } from '~/src/app/shared/components/news-list/news-list.component';
import {WidgetContainerComponent} from "~/src/app/shared/components/widget-container/widget-container.component";
import {IconComponent} from "~/src/app/core/components/icon/icon.component";
import { PercentBarComponent } from './percent-bar/percent-bar.component';

import {VideoComponent} from "~/src/app/shared/components/video/video.component";
import {LinkListItemComponent} from "~/src/app/shared/components/link-list/link-list-item.component";
import {ButtonComponent} from "~/src/app/shared/components/button/button.component";
import {
  RecommendedChallengesComponent
} from "~/src/app/shared/components/recommended-challenges/recommended-challenges.component";
import {VideoJsComponent} from "~/src/app/shared/components/video-js/video-js.component";
import { ListChallengeComponent } from './components/list-challenge/list-challenge.component';
import {
  ListChallengeItemComponent
} from "~/src/app/shared/components/list-challenge-item/list-challenge-item.component";
import {RouterLink} from "@angular/router";
import {ModalComponent} from "~/src/app/core/components/modal/modal.component";
import {FullscreenOverlayContainer, OverlayContainer, OverlayModule} from "@angular/cdk/overlay";
import {
  ReadinessIndicatorComponent
} from "~/src/app/shared/components/readiness-indicator/readiness-indicator.component";
import {SwipePaginatorComponent} from "~/src/app/shared/components/swipe-paginator/swipe-paginator.component";
import {MenuButtonComponent} from "~/src/app/shared/components/menu-button/menu-button.component";
import {
  NotificationButtonComponent
} from "~/src/app/shared/components/notification-button/notification-button.component";
import {
  NotificationSettingsComponent
} from "~/src/app/shared/components/notification-settings/notification-settings.component";
import {CheckboxToggleComponent} from "~/src/app/shared/components/checkbox-toggle/checkbox-toggle.component";
import {
  NotificationListItemComponent
} from "~/src/app/shared/components/notification-list-item/notification-list-item.component";
import {CountdownComponent} from "~/src/app/shared/components/countdown/countdown.component";
import {SelectComponent} from "~/src/app/shared/components/select/select.component";
import {QuizItemComponent} from "~/src/app/shared/components/quiz-item/quiz-item.component";
import {QuizComponent} from "~/src/app/pages/pages/quiz/quiz.component";
import {AnswerListComponent} from "~/src/app/pages/pages/quiz/quiz-types/answer-list/answer-list.component";
import {AnswerSelectComponent} from "~/src/app/pages/pages/quiz/quiz-types/answer-select/answer-select.component";
import {
  AnswerDragAndDropComponent
} from "~/src/app/pages/pages/quiz/quiz-types/answer-drag-and-drop/answer-drag-and-drop.component";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SubscriptionComponent} from "~/src/app/shared/components/subscription/subscription.component";
import {AnswerTextComponent} from "~/src/app/pages/pages/quiz/quiz-types/answer-text/answer-text.component";
import {
  HorisontalProgressionIndicatorComponent
} from "~/src/app/shared/components/horisontal-progression-indicator/horisontal-progression-indicator.component";
import {FavoriteService} from "~/src/app/shared/services/favorite.service";
import {ListCoursesComponent} from "~/src/app/shared/components/list-courses/list-courses.component";

@NgModule({
    declarations: [
        AccountLevelComponent,
        TabsComponent,
        ActiveTasksComponent,
        TaskSuccessComponent,
        SearchComponent,
        NewsListComponent,
        PauseActiveModulesComponent,
        InputComponent,
        ButtonComponent,
        WidgetContainerComponent,
        PercentBarComponent,
        RecommendedChallengesComponent,
        VideoJsComponent,
        VideoComponent,
        LinkListItemComponent,
        ListChallengeComponent,
        ListChallengeItemComponent,
        ReadinessIndicatorComponent,
        SwipePaginatorComponent,
        MenuButtonComponent,
        NotificationButtonComponent,
        NotificationSettingsComponent,
        CheckboxToggleComponent,
        NotificationListItemComponent,
        CountdownComponent,
        SelectComponent,
        QuizItemComponent,
        QuizComponent,
        AnswerListComponent,
        AnswerSelectComponent,
        AnswerTextComponent,
        AnswerDragAndDropComponent,
        SubscriptionComponent,
        HorisontalProgressionIndicatorComponent,
        ListCoursesComponent
    ],
    exports: [
        AccountLevelComponent,
        TabsComponent,
        ActiveTasksComponent,
        TaskSuccessComponent,
        SearchComponent,
        NewsListComponent,
        PauseActiveModulesComponent,
        InputComponent,
        ButtonComponent,
        WidgetContainerComponent,
        PercentBarComponent,
        RecommendedChallengesComponent,
        VideoJsComponent,
        VideoComponent,
        LinkListItemComponent,
        ListChallengeComponent,
        ListChallengeItemComponent,
        ReadinessIndicatorComponent,
        SwipePaginatorComponent,
        MenuButtonComponent,
        CheckboxToggleComponent,
        NotificationButtonComponent,
        NotificationSettingsComponent,
        NotificationListItemComponent,
        CountdownComponent,
        SelectComponent,
        QuizItemComponent,
        QuizComponent,
        AnswerListComponent,
        AnswerSelectComponent,
        AnswerDragAndDropComponent,
        AnswerTextComponent,
        SubscriptionComponent,
        HorisontalProgressionIndicatorComponent,
        ListCoursesComponent
    ],
	imports: [CommonModule, IonicModule, ReactiveFormsModule, IconComponent, FormsModule, RouterLink, ModalComponent, OverlayModule, DragDropModule, NgOptimizedImage],
  providers: [
    FavoriteService,
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer}
  ],
})
export class SharedModule {}
