import { Injectable } from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  ReplaySubject,
  Subject,
  lastValueFrom,
} from "rxjs";
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import {
  map,
  distinctUntilChanged,
} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LocalstorageService} from "src/app/core/services/localstorage.service";
import {ToastService} from "src/app/core/services/toast-service.service";

type Profile = {
  username: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  password?: string;
  password_confirm?: string;
  marketing_subscribe: number;
  firebase_token: string;
  device_platform: string;
  n_en_type_practise_session: number;
  n_en_type_hint: number;
  n_en_type_motivational_message: number;
  custom_motivational_quote: string;
  n_en_type_weekly_progress_report: number;
  n_en_via_push: number;
  n_en_via_email: number;
  n_en_via_calendar: number;
  is_subscribed: number;
};



@Injectable()
export class UserService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public isAdmin : boolean = false;
  public user : any; // currentUser subject is not working correctly
  public userLogiinSubject = new Subject();
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService,
    private toastr: ToastService,
    private router: Router,
    private localStorageService: LocalstorageService
  ) {}

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate(): void {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get<any>('/user')
        .subscribe({
          next: (v) => this.setAuth(v.user),
          error: (e) => this.purgeAuth(),
        });
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  getUser() {
    return this.apiService.get<any>('/user');
  }

  setAuth(
    user: any // User
  ) {
    this.user = user;
    // this.selectedOrganizationId =user.organizationId;
    // Save JWT sent from server in localstorage
    // this.jwtService.saveToken(user.token);
    this.jwtService.saveToken(user.jwt);

    this.localStorageService.setItem('userId', user.id + '');
    // this.isAdmin = (user.role === 'admin' || user.role === 'superadmin');
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    setTimeout(() => {
      return this.router.navigateByUrl('/');
    });
  }

  logout(){
    // @ts-ignore
    this.isAuthenticatedSubject.next(null);
  }

  purgeAuth() {
    this.user = {} as User;
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({} as User);
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);

    this.localStorageService.removeItem('userId');
    localStorage.removeItem('userId');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('jwtToken');
  }

  attemptAuth(authType: 'login' | 'registration' | 'activate' | 'forgotPassword' | 'newpass', credentials: any): Observable<User> {
    let params: any = {user: credentials};
    let relativePath = '';

    switch (authType) {
      case 'login':
        relativePath = '/login';
        params =      {
          "identity": credentials.email,
          "password": credentials.password
        }
        break;
      case 'registration':
        relativePath = '';
        break;
      case 'activate':
        relativePath = '/activate';
        break;
      case 'forgotPassword':
        relativePath = '/send-forgot-password-email';
        break;
      case 'newpass':
        relativePath = '/modify-password';
        params = credentials;
        break;
      default:
        break;
    }

    return this.apiService.post<any>('/users' + relativePath, params)
      .pipe(map(
        data => {
          // because we need to click on validation email's link
          if (authType !== 'registration') {
            this.setAuth(data.data);
          }

          if (authType === 'newpass') {
            this.toastr.openToast('Email sent. You need to check your e-mail!');
          }

          return data;
        }
      ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user: User): Observable<User> {
    return this.apiService
      .put<any>('/user', { user })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data);
        return data.user;
      }));
  }
  // @ts-ignore
  resendActivationByEmail(email) {
    return this.apiService
      .post<any>('/user/resend-activation', { email })
      .pipe(map(data => {
        // Update the currentUser observable
        this.currentUserSubject.next(data);
        return data;
      }));
  }

  refreshUser(user: User) {
    this.currentUserSubject.next(user);
  }

  register(param: {
    firstname: string | null | undefined;
    password: string | null | undefined;
    password_confirm: string | null | undefined;
    phone: string | null | undefined;
    identity: string | null | undefined;
    marketing_subscribe: boolean | null | undefined;
    email: string | null | undefined;
    username: string | null | undefined;
    lastname: string | null | undefined
  }): Observable<any> {
    return this.apiService
      .post<any>('/users/register', param)
      .pipe(map(data => {
        return data;
      }));
  }

  updateProfile(param: {
    firstname: string | null | undefined;
    password: string | null | undefined;
    password_confirm: string | null | undefined;
    phone: string | null | undefined;
    identity: string | null | undefined;
    marketing_subscribe: boolean | null | undefined;
    email: string | null | undefined;
    username: string | null | undefined;
    lastname: string | null | undefined
  }): Observable<any> {
    return this.apiService
        .put<any>('/users/profile', param)
        .pipe(map(data => {
          return data;
        }));
  }

  updateNotificationSettings(param: Profile): Observable<any> {
    return this.apiService
        .put<any>('/users/profile', param)
        .pipe(map(data => {
          return data;
        }));
  }

  getProfile() {
    return this.apiService
        .get<any>('/users/profile')
        .pipe(map(data => {
          return data;
        }));
  }


  activateUser(id: number, hash: string) {
    return this.apiService
        .post<any>('/users/activate', {id, code: hash})
        .pipe(map(data => {
          return data;
        }));
  }

  forgotPassword(email: string) {
    return this.apiService
        .post<any>('/users/forgot_password', {identity: email})
        .pipe(map(data => {
          return data;
        }));
  }

  changePassword(id: number, hash: string, password: string, passwordAgain: string) {
    return this.apiService
        .post<any>('/users/reset_password', {
          id,
          code: hash,
          "new_password": password,
          "new_password_confirm": passwordAgain

  })
        .pipe(map(data => {
          return data;
        }));
  }

    async getSubscriptionData(): Promise<{
        isActive: boolean;
        expirationDate: string;
    }> {
        const resp = await lastValueFrom(this.getProfile());

        return {
          isActive: resp.data.is_subscribed === '1',
          expirationDate: resp.data.subscription_expiry
        }
    }
}
