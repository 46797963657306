import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-activate-your-account',
  templateUrl: './activate-your-account.component.html',
  styleUrls: ['./activate-your-account.component.scss']
})
export class ActivateYourAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
