import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule
} from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/index';
import { ListErrorsComponent } from '~/src/app/core/components/listerrors/list-errors.component';
import { ApiService } from '~/src/app/core/services/api.service';
import { AuthGuard } from '~/src/app/core/services/auth-guard.service';
import { JwtService } from '~/src/app/core/services/jwt.service';
import { UserService } from '~/src/app/core/services/user.service';
// import { BrowserCookiesModule } from 'ngx-utils-cookies-port';
import { LandingPagesModule } from "~/src/app/core/modules/landing-pages/landing-pages.module";
import {NoNetInterceptorService} from "~/src/app/core/interceptors/no-net-interceptor.service";
import {NetworkCheckService} from "~/src/app/core/services/network-check.service";
import {ToastService} from "~/src/app/core/services/toast-service.service";
import {SafePipe} from "~/src/app/core/pipes/htmlsanitizer.pipe";
import {FilesUploadDirective} from "~/src/app/core/directives/files-upload.directive";
import {DynamicFormComponent} from "~/src/app/core/components/dynamic-form/dynamic-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";

import {SharedModule} from "~/src/app/shared/shared.module";
import {AuthInterceptor} from "~/src/app/core/interceptors/auth.interceptor";

const interceptorProviders =
  [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoNetInterceptorService, multi: true }
  ];


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    LandingPagesModule,
    ReactiveFormsModule,
    IonicModule,
    SharedModule
  ],
  providers: [
    interceptorProviders,
    ApiService,
    AuthGuard,
    JwtService,
    UserService,
    ToastService,
    NetworkCheckService,
    SafePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [
    ListErrorsComponent,
    FilesUploadDirective,
    DynamicFormComponent
  ],
  declarations: [
    ListErrorsComponent,
    FilesUploadDirective,
    DynamicFormComponent
  ]
})
export class CoreModule { }
