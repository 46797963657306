export class State {

  ready: boolean = false;
  error: string = '';

  isProcessingOrder: boolean = false;
  isRefreshing: boolean = false;
  isVerifying: boolean = false;

  activeSubscription?: CdvPurchase.VerifiedPurchase;
  expiredSubscription?: CdvPurchase.VerifiedPurchase;

  products: CdvPurchase.Product[] = [];
  purchases: CdvPurchase.VerifiedPurchase[] = [];
  transactions: CdvPurchase.Transaction[] = [];

  /**
   * Update the state and refresh the user interface.
   */
  set(attr: Partial<State>) {
    Object.assign(this, attr);
  }

}
