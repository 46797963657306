<!-- countdown.component.html -->
<div class="countdown" *ngIf="color">
  <svg class="timer-ring">
    <circle
      cx="50%"
      cy="50%"
      r="45%"
      fill="transparent"
      [attr.stroke]="color"
      stroke-width="1"
      stroke-dasharray="283"
      [style.stroke-dashoffset]="calculateDashOffset()"
    ></circle>
  </svg>
  <div class="timer" style="font-size: {{ fontSize }}px; color: {{color}}">{{ currentSeconds }}</div>
</div>
