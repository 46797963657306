import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {NavController} from "@ionic/angular";
import {ToastService} from "src/app/core/services/toast-service.service";

export type ListItem = {
  progression: { status: string };
  practiceSessionId?: number;
  trickLongtext?: string;
  is_paywalled?: boolean; // if it false, it is free
  is_bookmarked?: boolean;
  isFree?: boolean;
  title: string;
  status:  'generic' | 'inactive' | 'active' | 'suspended' | 'in_progress' | 'completed';
  state?: 'done' | 'inprogress' | 'untouched' | 'favorite' | '';
  id?: number;
}

@Component({
  selector: 'app-list-challenge-item',
  templateUrl: './list-challenge-item.component.html',
  styleUrls: ['./list-challenge-item.component.scss'],
})
export class ListChallengeItemComponent  implements OnInit {
  @ViewChild('trigger') trigger !:  Element;

  @Input({ required: true }) item !: ListItem;
  @Input() isLinkDisabled: boolean = false;
  @Input() mode: 'default' | 'trick' | 'challenge' | 'practiceSession' | 'quiz' = 'challenge';

  isModalShown : boolean = false;


  constructor(
      private navCtrl: NavController,
      private toastr: ToastService
  ) { }

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.isModalShown = false;
  }


  ngOnInit() {
    this.handleStateByStatus();
    // a feliratkozó kurzus haladás állapotát felülírhatja, h kedvencelte-e (is_bookmarked), ha igen, akkor az felülírja az állapotmezőt
    if (this.item.is_bookmarked ) {
      this.item.state = 'favorite';
    }
  }

  clickItem(isForced = false) {
    if (isForced && this.item.is_bookmarked ) {
      // todo set it to not fav, make http call
      this.handleStateByStatus();

      return;
    }

    switch (this.mode) {
      case "challenge":
        if (isForced) {
          this.navCtrl.navigateForward('/pages/challenge-preview/forced/' + this.item.id);

          return;
        }
        this.navCtrl.navigateForward('/pages/challenge-preview/' + this.item.id);
        break;
        case "practiceSession":
        this.navCtrl.navigateForward('/pages/challenge/' + this.item.id + '/' + this.item.practiceSessionId)
        break;
      case "trick":
        this.isModalShown = true;
        break;
      case "quiz":
        this.toastr.openToast('Kvíz jelenleg nem elérhető');
        break;
      default:

        break;
    }
  }
  private handleStateByStatus() {
    if (this.item?.state === 'favorite') {
      return;
    }
    const status: 'inactive' | 'active' | 'completed' | 'finished' | 'postponed' = this.item.progression?.status as any;

    switch (status) {
      case 'inactive':
        this.item.state = 'untouched';
        break;
      case 'active':
        this.item.state = 'inprogress';
        break;
      case 'postponed':
        this.item.state = 'untouched';
        break;
      case 'completed':
        this.item.state = 'done';
        break;
      case 'finished':
        this.item.state = 'done';
        break;
      default:
        this.item.state = 'untouched';
        break;
    }
  }
}
