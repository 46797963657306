import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
// import { CookiesService } from 'ngx-utils-cookies-port';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    // @Inject(CookiesService) private cookies: any
    // private cookies: CookiesService
  ) {
  }

  getItem(key: string): any {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(key)) {
        const item = localStorage .getItem(key) as any;

        return item;
      }
    } else {
      // this.cookies.get(key);
    }
  }

  setItem(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.setItem(key, value);
    } else {
      // this.cookies.put(key, value);
    }
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key);
    } else {
      // this.cookies.remove(key);
    }
  }
  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    } else {
      // this.cookies.removeAll();
    }
  }
}
