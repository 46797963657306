import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-active-tasks',
  templateUrl: './active-tasks.component.html',
  styleUrls: ['./active-tasks.component.scss'],
})
export class ActiveTasksComponent implements OnInit {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() activePractiseSessionsResult!: any;

  practiceSessionsResults : any = [];
  from: number = 0;
  to: number = 0;

  ngOnInit() {
    this.practiceSessionsResults = this.activePractiseSessionsResult.practise_sessions_results;
    this.from = this.activePractiseSessionsResult.completed_practise_sessions_count;
    this.to = this.activePractiseSessionsResult.practise_sessions_count;
  }

  quizResultStatusClassFormatter(status: string): string {
    let formattedClass = 'empty';

    if (status === 'finished' || status === 'completed_successful') {
      formattedClass = 'success';
    } else if (status === 'completed_failed') {
      formattedClass = 'failed';
    }

    return 'bubble ' + formattedClass;
  }
}
