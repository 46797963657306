import {Component, Input, OnInit} from '@angular/core';
import {CourseDto} from "~/src/app/shared/models/courseDto";

@Component({
  selector: 'app-list-courses',
  templateUrl: './list-courses.component.html',
  styleUrls: ['./list-courses.component.scss'],
})
export class ListCoursesComponent  implements OnInit {
  @Input() courses: CourseDto[];
  constructor() { }

  ngOnInit() {}

}
