import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageService} from "src/app/shared/services/storage.service";
import {FavoriteService} from "src/app/shared/services/favorite.service";
import {ActivityLogService} from "src/app/shared/services/activitylog.service";

@Component({
  selector: 'app-link-list-item',
  templateUrl: './link-list-item.component.html',
  styleUrls: ['./link-list-item.component.scss'],
})
export class LinkListItemComponent implements OnInit {
  @Input() href !: string;
  @Input() counter !: number;
  @Input() text !: string;
  @Input() longtext = '';
  @Input() color !: string;
  @Input() tip: any;
  @Input() isBiggerHeight: boolean = false;
  @Input() item: any;
  @Input() contentType!: string;
  @Input() isHtml: boolean = false;
  @Input() isFavorite: boolean | null = null;
  @Input() isOpen = false;
  @Input() isDisableOpenPopup = false;
  @Output() changeFavorite = new EventEmitter<boolean>();
  @Output() reloadPageEmitter: EventEmitter<any> = new EventEmitter<any>();

  private colors = [
      '#EFC376',
      '#FFB6A3',
      '#537098',
  ];
  private storageKey = 'actualColorIndexOfLinkListItem';
  private favoriteId : number | undefined;
  isModalShown = false;


  constructor(
      private storageService: StorageService,
      private favoriteService: FavoriteService,
      private activityLogService: ActivityLogService
  ) {}

  ngOnInit() {
    this.handleColor();
    this.handleFavorite();
    if (this.isOpen && !this.isDisableOpenPopup) {
      this.isModalShown = true;
    }
    this.handleLog();
  }

  setFavorite() {
    if (!this.isFavorite) {
      this.favoriteService.setFavorite(this.contentType, this.item.id).subscribe((res: any) => {
        this.isFavorite = true;
        this.changeFavorite.emit(this.isFavorite);
      });
    } else {
      this.favoriteService.getFavoritesByContentType(this.contentType).subscribe((res: any) => {
        const relevantFavorite = res.data.find((fav: any) => fav.content_id === this.item.id);
        this.favoriteId = relevantFavorite.id;
        this.favoriteService.deleteFavorite(relevantFavorite.id).subscribe((res: any) => {
          this.isFavorite = false;
          this.changeFavorite.emit(this.isFavorite);
        });
      });
    }
  }

  private handleColor() {
    let actualColorIndex: any = this.storageService.get(this.storageKey)

    if (this.color) {
      return;
    }

    if (!actualColorIndex) {
      actualColorIndex = 0;
      this.color = this.colors[actualColorIndex];
      this.storageService.set(this.storageKey, '' + actualColorIndex);

      return;
    }

    if (actualColorIndex) {
      actualColorIndex = parseInt(actualColorIndex, 10);

      if (actualColorIndex == this.colors.length -1 ) {
        actualColorIndex = 0;
      } else {
        actualColorIndex++;
      }

      this.storageService.set(this.storageKey, '' + actualColorIndex);
      this.color = this.colors[actualColorIndex];
    }
  }

  private handleFavorite() {
    if (this.contentType && this.isFavorite === null) {
      this.favoriteService.getFavoritesByContentType(this.contentType).subscribe((res: any) => {
        if (!res.data) {
          return;
        }
        const relevantFavorite = res.data.find((fav: any) => fav.content_id === this.item.id);

        if (relevantFavorite) {
          this.favoriteId = relevantFavorite.id;
          this.isFavorite = true;
        }
      });
    }
  }

  private handleLog() {
    if (this.item && this.item.id && this.contentType === 'hint' && this.isModalShown) {
      this.activityLogService.hintsOpen(this.item.id).subscribe((res) => {
        if (this.tip) {
          this.tip.progression = {
            status: 'read'
          }
        }

        if (res.practise_session_status === 'completed') {
          this.reloadPageEmitter.emit();
        }
      });
    }
  }

  open() {
    if (this.isDisableOpenPopup) {
      return;
    }
    this.isModalShown = true;
    this.handleLog();
    if (this.tip) {
      this.tip.progression = {
        status: 'read'
      }
    }
  }

  protected readonly close = close;

  closePopup() {
    this.isModalShown = !this.isModalShown;
    if (this.tip) {
      this.tip.isOpen = false;
    }
  }
}
