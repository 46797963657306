<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <ng-container *ngFor="let controlName of getControlNames(formGroup)">
    <ng-container [ngSwitch]="getControlType(formGroup.get(controlName))">
      <div *ngSwitchCase="'group'">
        <fieldset [formGroup]="formGroup.get(controlName)">
          <legend>{{ controlName | titlecase }}</legend>
          <app-dynamic-form [formGroup]="formGroup.get(controlName)"></app-dynamic-form>
        </fieldset>
      </div>
      <div *ngSwitchCase="'array'">
        <ng-container *ngFor="let group of (formGroup.get(controlName))['controls']; let i = index">
          <fieldset [formGroup]="group">
            <legend>{{ controlName | titlecase }} {{ i + 1 }}</legend>
            <app-dynamic-form [formGroup]="group"></app-dynamic-form>
          </fieldset>
        </ng-container>
        <button type="button" (click)="addControl(controlName)">Add {{ controlName | titlecase }}</button>
      </div>
      <div *ngSwitchDefault>
        <label>{{ controlName | titlecase }}</label>
        <ion-input *ngIf="isControlType(controlName, 'input')" [formControlName]="controlName"></ion-input>
        <ion-textarea *ngIf="isControlType(controlName, 'textarea')" [formControlName]="controlName"></ion-textarea>
        <ion-select *ngIf="isControlType(controlName, 'select')" [formControlName]="controlName">
          <ion-select-option *ngFor="let option of formGroup.get(controlName)?.valueOptions" [value]="option">
            {{ option }}
          </ion-select-option>
        </ion-select>
        <ion-list *ngIf="isControlType(controlName, 'checkbox')">
          <ion-checkbox *ngFor="let option of formGroup.get(controlName)?.valueOptions" [formControlName]="controlName"
                        [value]="option">{{ option }}</ion-checkbox>
        </ion-list>
        <ion-list *ngIf="isControlType(controlName, 'radio')" [formControlName]="controlName">
          <ion-radio *ngFor="let option of formGroup.get(controlName)?.valueOptions" [value]="option">
            {{ option }}
          </ion-radio>
        </ion-list>
        <div *ngIf="formGroup.get(controlName)?.invalid && formGroup.get(controlName)?.touched">
          <span *ngIf="formGroup.get(controlName)?.errors?.required">This field is required.</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div>
    <button type="submit" [disabled]="!formGroup.valid">Submit</button>
  </div>
</form>
