import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() color !: 'primary' | 'secondary' | 'danger' | 'orange' | 'blue' | 'green' | 'dark-blue' | 'dark-green';
  @Input() type: string | undefined;
  @Input() disabled: boolean = false;
}
