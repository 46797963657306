<button
  class="shared-btn font-medium-size py-8 fw-700"
  [ngClass]="{
  'orange': color === 'orange',
  'dark-green': color === 'dark-green',
  'dark-blue': color === 'dark-blue',
  'green': (color === 'secondary' || color === 'green'),
  'primary': (color === 'primary' || color === 'blue'),
  'disabled': disabled
  }"
  [disabled]="disabled"
>
  <span class="button-content">
    <ng-content></ng-content>
  </span>
</button>
