import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { JwtService } from './jwt.service';
import { throwError } from 'rxjs';
import { take} from 'rxjs/operators';
import {Router} from '@angular/router';
// @ts-ignore
import param from 'jquery-param';
import {Injectable} from "@angular/core";

@Injectable()
export class ApiService {
  private token_type = 'Bearer';
  private token: string;
  private url = environment.api_url;

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) {
    this.token = this.jwtService.getToken();
  }
  public put<T>(relativePath: string, params?: any): Observable<T> {
    return this.request('put', relativePath, params );
  }

  public post<T>(relativePath: string, params?: any): Observable<T> {
    return this.request('post', relativePath, params );
  }

  public delete<T>(relativePath: string, params?: any): Observable<T> {
    return this.request('delete', relativePath, params );
  }

  public get<T>(relativePath: string, params?: any): Observable<T> {
    return this.request('get', relativePath, params );
  }

  private request(httpRequestType: string, urlPart: string, params?: any): Observable<any> {
    const headerSettings: { [name: string]: string | string[]; } = {};
    // const body = new URLSearchParams();
    let headers;
    let options;

    if (!this.token) {
      console.log('we need here to navigate to login?');
      // this.router.navigateByUrl('/login');
      /**
       * todo I think we need to add a flag for non authenticated pages for handle the navigation for lost token.
       */
    } else {
      headerSettings['Authorization'] = this.token_type + ' ' + this.token;
    }

    headers = new HttpHeaders(headerSettings);

    options = {
      headers //             params: new HttpParams().set(params)
    };

    if (typeof params === 'string') {
      // @ts-ignore
      return this.http[httpRequestType](this.url + urlPart, params, options).pipe(take(1));
    } else if (typeof params === 'object' && Object.keys(params).length > 0) {
      if (httpRequestType === 'get') {
        if (params.constructor && params.constructor.name === 'HttpParams') {
          // @ts-ignore
          return this.http[httpRequestType](this.url + urlPart, {...options, param: params}).pipe(take(1));
        } else {
          const paramString =  param(params);
          return this.http.get(this.url + urlPart + '?' + paramString, options);
        }
      } else {
        // @ts-ignore
        return this.http[httpRequestType](this.url + urlPart, params, options).pipe(take(1));
      }
    } else {
      // @ts-ignore
      return this.http[httpRequestType](this.url + urlPart, options).pipe(take(1));
    }
  }

  public getUrlParams(topObject: Object, params?: URLSearchParams) {
    Object.keys(topObject).forEach(key => {
      // @ts-ignore
      if (typeof topObject[key] === 'object') {
        // @ts-ignore
        this.setUrlParamsRecursive(key, topObject[key], params);
      } else {
        // @ts-ignore
        const param = topObject[key];

        if (!params) {
          params = new URLSearchParams();
          params.set(key, JSON.stringify(param));
        } else {
          params.append(key, JSON.stringify(param));
        }
      }
    });

    return params;
  }

  private setUrlParamsRecursive(path: string, obj: any, urlParams?: URLSearchParams): void {
    if (typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        const value = obj[key];

        if (typeof value === 'object') {
          this.setUrlParamsRecursive(`${path}[${key}]`, value, urlParams);
        } else {
          // @ts-ignore
          urlParams.append(`${path}[${key}]`, JSON.stringify(value));
        }
      });
    } else {
      // @ts-ignore
      urlParams.append(path, JSON.stringify(obj));
    }
  }

  private formatErrors(error: any) {
    return  throwError(error.error);
  }
}
