import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {StorageService} from "src/app/shared/services/storage.service";

@Component({
  selector: 'app-quiz-item',
  templateUrl: './quiz-item.component.html',
  styleUrls: ['./quiz-item.component.scss'],
})
export class QuizItemComponent  implements OnInit {
  @Input() href: string | undefined;
  @Input() counter!: number;
  @Input() text!: string;
  @Input() title!: string;
  @Input() color: string | undefined;
  @Input() quiz!: any;
  @Input() parentData!: any;
  @Input() isFavorite = false;
  @Input() isOpen = false;
  @Input() isDisableOpenPopup = false;
  @Input() type: "list" | "yesno" | "select" | "draganddrop" | undefined;
  @Input() quizType: 'practiceSession' | 'lession' | 'practiceSessionVideo' = 'lession';
  @Output() filledEmittter = new EventEmitter();
  @Output() progressionStatusChangeEmitter = new EventEmitter();

  isModalShown = false;
  progressionStatus: string | null | undefined;

  public isBlurOnInactiveFeature = false;

  private colors = [
    '#EFC376',
    '#FFB6A3',
    '#537098',
  ];
  private storageKey = 'actualColorIndexOfLinkListItem';

  constructor(
    private storageService: StorageService
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  handleEscape(event: KeyboardEvent): void {
    this.isModalShown = false;
  }


  ngOnInit() {
    if(this.quizType === 'practiceSession' ) {
      this.isBlurOnInactiveFeature = true;
    }
    this.handleColor();
    if (this.isOpen && !this.isDisableOpenPopup) {
      this.isModalShown = true;
    }
    const quiz = this.quiz;
    const progression = quiz.progression;

    if (progression) {
      this.progressionStatus = progression.status;
    }
  }

  setFavorite() {
    this.isFavorite = !this.isFavorite;
  }

  private handleColor() {
    let actualColorIndex: any = this.storageService.get(this.storageKey)

    if (this.color) {
      return;
    }

    if (!actualColorIndex) {
      actualColorIndex = 0;
      this.color = this.colors[actualColorIndex];
      this.storageService.set(this.storageKey, '' + actualColorIndex);

      return;
    }

    if (actualColorIndex) {
      actualColorIndex = parseInt(actualColorIndex, 10);

      if (actualColorIndex == this.colors.length -1 ) {
        actualColorIndex = 0;
      } else {
        actualColorIndex++;
      }

      this.storageService.set(this.storageKey, '' + actualColorIndex);
      this.color = this.colors[actualColorIndex];
    }
  }

  quizCompleted($event: any) {
    this.progressionStatus = 'completed_successful';
    this.progressionStatusChangeEmitter.emit(this.progressionStatus);
  }

  isWrongEmitter($event: any) {
    this.progressionStatus = 'completed_failed';
    this.progressionStatusChangeEmitter.emit(this.progressionStatus);
  }

  filledEmit($event: any) {
    if ($event) {
      $event.progressionStatus = this.progressionStatus;
      this.filledEmittter.emit($event);
    } else {
      this.filledEmittter.emit(this.progressionStatus);
    }
  }

  open() {
    if(this.isDisableOpenPopup || (this.quiz.progression?.status === 'inactive' && this.isBlurOnInactiveFeature)) {
      return;
    }
    this.isModalShown = true;
  }
}
