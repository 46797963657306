import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Capacitor,
} from "~/node_modules/@capacitor/core";
import {PushNotifications} from '@capacitor/push-notifications';
import { ApiService } from "~/src/app/core/services/api.service";
import * as CapDevice from '@capacitor/device';
import {ToastService} from "~/src/app/core/services/toast-service.service";
import {
    ActionPerformed,
    PushNotificationSchema,
    Token
} from "@capacitor/push-notifications/dist/esm/definitions";
import {Platform} from "@ionic/angular";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {NotificationService} from "~/src/app/shared/services/notification.service";



@Injectable()
export class FcmService {
  uniqueDeviceId:string | null = null;


  constructor(private router: Router,
              private toastManager: ToastService,
              private apiService: ApiService,
              private platform: Platform,
              private afAuth: AngularFireAuth,
              private afMessaging: AngularFireMessaging,
              private notificationService: NotificationService
  ) { }

 async initPush() {
      /*
     this.afAuth.signInAnonymously().then(() => {
         this.afMessaging.getToken.subscribe((token) => {
             console.log('Got FCM token: ', token);
             alert(token);

             this.addPushListeners();
         });
     });
*/
     this.addPushListeners();


  }

  private async addPushListeners() {
      const isNative = Capacitor.isNativePlatform();

      if (isNative) {
          const deviceId: CapDevice.DeviceId = await CapDevice.Device.getId();
          // @ts-ignore

          this.uniqueDeviceId = deviceId.identifier;
          await this.registerPush();
      } else {
          // this.initPushForWeb();
      }
  }

  private async initPushForWeb(): Promise<void> {
        const messaging = this.afMessaging;
        messaging.requestPermission
            .subscribe(
                (allowed: NotificationPermission) => {
                    if (allowed) {
                        messaging.getToken
                            .subscribe(
                                (token: string | null): void => {
                                    console.log('Got FCM token: ', token);
                                    // this.addPushListeners();
                                },
                                (error): void => {
                                    console.error('Error getting FCM token: ', error);
                                }
                            );
                    } else {
                        console.error('User declined FCM permission');
                    }
                },
                (error) => {
                    console.error('Error getting FCM permission: ', error);
                }
            );
  }

    private setupPushNotifications() {
        PushNotifications.requestPermissions().then(result => {
            if (result.receive === 'granted') {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                console.error('Push notifications permission denied');
            }
        });

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token: Token) => {
            // Send the token to your server if needed
            console.log('Push registration success, token: ' + token.value);
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error: any) => {
            console.error('Error during push registration: ' + JSON.stringify(error));
        });

        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
            console.log('Push received: ' + JSON.stringify(notification));
        });

        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
            console.log('Push action performed: ' + JSON.stringify(notification));
        });
    }

  private async registerPush() {
      // @ts-ignore
    PushNotifications.addListener(
        'registration',
        async (token: Token) => {
          const _token = token.value;

          if (_token) {
            const profileResp = await this.apiService.get('/users/profile',{token: _token, device: this.uniqueDeviceId}).toPromise() as any;
            const platform = Capacitor.getPlatform();

            if (profileResp) {
              const payload = {
                ...profileResp.data,
                'firebase_token': _token,
                'device_platform': platform === 'android' ? 'android' : 'web',
              }

              // save firebase token
              this.apiService.put('/users/profile', payload).toPromise().then((res) => {});
            }
          }

/*
          if(!this.uniqueDeviceId){
            return ;
          }

          // alert('todo register fcm token')
          return;
          const correctDevice = await this.apiService.post('/device/checkIfDeviceIdIsUnique',{token: _token, device: this.uniqueDeviceId}).toPromise();

           if(!correctDevice){
             this.apiService.post('/device', {token: _token, uniqueId: this.uniqueDeviceId})
               .subscribe((res) => {
                 console.log(res);
               });
           }
 */
         }
      );



    // @ts-ignore
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    // @ts-ignore
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        if(notification.title && notification.title.length > 0) {
          this.toastManager.openToast(notification.title);
        }
        setTimeout(() => {
          this.notificationService.notificationRecivedEmitter.emit();
        }, 1000);
      }
    );

      // @ts-ignore
    PushNotifications.addListener(
        'pushNotificationActionPerformed',
        async (notification: ActionPerformed) => {
          const data = notification.notification.data;

          setTimeout(() => {
            this.notificationService.notificationRecivedEmitter.emit();
          }, 1000);
          setTimeout(() => {
            if (data && data.route) {
              this.router.navigateByUrl(data.route);

              return;
            }
            this.router.navigateByUrl(`/pages/notifications`);
          }, 600);
        }
      );


  // @ts-ignore
    await PushNotifications.requestPermissions().then(async (permission) => {
      if (permission.receive) {
        // Register with Apple / Google to receive push via APNS/FCM
        // @ts-ignore
        await PushNotifications.register();
      } else {
        // No permission for push granted
      }
    });



  }
}
