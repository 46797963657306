import { Injectable } from '@angular/core';

export const TOKEN_NAME = 'jwtToken';
export const WELCOME_SCREEN_IS_VIEWED_KEY = 'isWelcomeScreenViewed';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {}

  public get(key: string): string | null {
    return localStorage.getItem(key);
  }

  public set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public getToken(): string | null {
    return this.get(TOKEN_NAME);
  }

  public resetToken(): void {
    this.remove(TOKEN_NAME);
  }
}
