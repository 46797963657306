import { Component, Input, OnInit } from '@angular/core';
import { NewsDto } from '../../models/newsDto';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent  implements OnInit {
  @Input() newslist: NewsDto[] = [];

  constructor() { }

  ngOnInit() {
  }

}
