<div class="modal-container">
  <div class="modal-background" (click)="close(null)" ></div>
  <div class="modal" [ngClass]="{'transparent': isTransparent, 'w-100': isFullWidth, 'is-video': isVideoModal, 'is-not-video': !isVideoModal}">
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="modal-card-body-inner" [ngClass]="{'is-not-video': !isVideoModal}">
          <app-icon *ngIf="!isHiddenCloseButton" class="modal-close {{ type }}" (click)="close(null)" [iconName]="'close-white.svg'"></app-icon>
          <ng-content></ng-content>
        </div>
      </section>
    </div>
  </div>
</div>
