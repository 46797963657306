import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-challenge-with-competencies',
  templateUrl: './list-challenge.component.html',
  styleUrls: ['./list-challenge.component.scss'],
})
export class ListChallengeComponent  implements OnInit {
  @Input() competencies: any[] = [];
  constructor() { }

  ngOnInit() {}

}
