import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit, OnDestroy {
  @Input() duration: number = 4; // sec
  @Input() fontSize: number = 12; // px
  @Input() paused: boolean = false;
  @Input() color: string = 'black';
  @Output() countdownFinished = new EventEmitter<boolean>();

  currentSeconds: number = 0;

  private interval: any;

  constructor() { }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown() {
    this.currentSeconds = this.duration;
    this.interval = setInterval(() => {
      if (!this.paused) {
        this.currentSeconds--;

        if (this.currentSeconds === 0) {
          clearInterval(this.interval);
          this.countdownFinished.emit(true);
        }
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  calculateDashOffset(): string {
    const progress = (this.duration - this.currentSeconds) / this.duration;
    const dashOffset = 283 * (1.2 - progress); // 12 óránál kezdődjön és jobbra fogyjon

    return `${dashOffset}px`;
  }
}
