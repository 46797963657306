import {
  Injectable,
  isDevMode,
} from "@angular/core";
import {Platform} from "@ionic/angular";
import {distinctUntilChanged} from "rxjs/operators";
import {ApiService} from "src/app/core/services/api.service";
import {
  BehaviorSubject,
  Observable,
} from "rxjs";
import {State} from "~/src/app/pages/pages/payment/state";
import {BarionPaymentService} from "~/src/app/shared/services/payment/barion-payment.service";
import {SubscriptionData} from "~/src/app/shared/services/payment/subscription-data";
import { Capacitor } from '@capacitor/core';
import "cordova-plugin-purchase"

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private store !: CdvPurchase.Store;
  //public storeState$ : Subject<State> = new Subject();
  //public storeState$ : Subject<State> = new Subject();
  //private state: State;
  private _stateUpdates: BehaviorSubject<State>;
  paymentPlatform: undefined | "barion" | CdvPurchase.Platform.APPLE_APPSTORE;
  private barion ?: BarionPaymentService;

  constructor(
      private platform: Platform,
      private apiService: ApiService
  ) {

    this.paymentPlatform = Capacitor.getPlatform() === "ios" ? CdvPurchase.Platform.APPLE_APPSTORE : "barion";

    const state = new State();
    this._stateUpdates = new BehaviorSubject(state);

    console.log('Supported payment platform: ' + this.paymentPlatform);
    console.table(state);

    //this.storeState$.next(state);

    // MUST WAIT for Cordova to initialize before referencing CdvPurchase namespace
    this.platform.ready().then(async () => {
      if(Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
        console.error('PaymentService init');
        this.store = CdvPurchase.store;

        await this.initAppStore();
      }
      else {
        this.barion = new BarionPaymentService(this.apiService);
      }
    });
  }

  private updateState(attr: Partial<State>): void {
    let state : State = this._stateUpdates.getValue();
    state.set(attr);
    this._stateUpdates.next(state);

    this.store.log.debug(JSON.stringify(state));
  }

  currentState$() {
    return this._stateUpdates.asObservable().pipe(distinctUntilChanged());
  }

  private async initAppStore() {

    this.store.verbosity = isDevMode() ? CdvPurchase.LogLevel.DEBUG : CdvPurchase.LogLevel.ERROR;

    this.store.error((err: any) => {
      this.updateState({
        isProcessingOrder: false,
        isVerifying: false,
        error: JSON.stringify(err),
        ...this.stateUpdates()
      });

      this.store.log.error('Store Error ' + JSON.stringify(err));
      return;
    });

    this.registerProducts();

    this.registerListeners();

    // this.setupVerification();

    const options = [
      {
        platform: CdvPurchase.Platform.APPLE_APPSTORE,
        options: {
          needAppReceipt: true,
          autoFinish: false,
        }
      } as CdvPurchase.PlatformOptions.AppleAppStore
    ];

    // itt nem szabad meghívni az store.update()-et,
    // az azáltal lefrissítendő adatok teljesen frissen megvannak!
    this.store.initialize(options).then(() => {

      this.updateState({ ready: true })

      //this.restorePurchases();

      //this.store.log.debug(JSON.stringify(this.store.localReceipts));
      //this.store.log.debug(JSON.stringify(this.store.localTransactions));
      //this.store.log.debug(JSON.stringify(this.store));
    }).then(err => this.store.log.error(JSON.stringify(err)));
  }

  subscribe(platform: CdvPurchase.Platform, productId: string, offerId: string) {

    this.updateState({ isProcessingOrder: true, error: ''} );

    const selectedProduct = {id: productId, platform: platform};

    if(this.store.owned(selectedProduct)) {
      this.store.manageSubscriptions(platform)
         .then(err => this.store.log.error(JSON.stringify(err)));

      this.store.log.debug(JSON.stringify(selectedProduct));
      return;
    }

    return this.store.get(productId, platform)?.getOffer(offerId)?.order()
      .then(error => {
        if (error) {
          this.updateState({ isProcessingOrder: false });

          if (error?.code === CdvPurchase.ErrorCode.PAYMENT_CANCELLED) {
            // payment cancelled by the user
          }

          this.store.log.error(error);
        }

      });
  }

  checkTransactionStatus(transactionId: number | string): Observable<SubscriptionData<any>> {
    return this.apiService.get(`/payment?transaction_id=${transactionId}`);
  }

  getProducts() {
    //return this.apiService.get('/shop/products?store_id=apple_app_store');

    // clicks.pipe(filter(ev => (<HTMLElement>ev.target).tagName === 'DIV'));

    //return this.currentState$;

    return;
  }

  private registerProducts(): void {
    const products = [
      ...[
        'apparently_apple_app_havi_elofizetes',
      ].map(id => ({
        id,
        platform: CdvPurchase.Platform.APPLE_APPSTORE,
        type: CdvPurchase.ProductType.PAID_SUBSCRIPTION,
        group: 'apparently – előfizetések',
      } as CdvPurchase.IRegisterProduct)),
    ];

    this.store.register(products);
  }

  private registerListeners(): void {
    /**
productUpdated: (cb: Callback<Product>, callbackName?: string) => (this.updatedCallbacks.push(cb, callbackName), ret),
receiptUpdated: (cb: Callback<Receipt>, callbackName?: string) => (this.updatedReceiptsCallbacks.push(cb, callbackName), ret),
updated: (cb: Callback<Product | Receipt>, callbackName?: string) => (this.updatedCallbacks.push(cb, callbackName), this.updatedReceiptsCallbacks.push(cb, callbackName), ret),
// owned: (cb: Callback<Product>) => (this.ownedCallbacks.push(cb), ret),
approved: (cb: Callback<Transaction>, callbackName?: string) => (this.approvedCallbacks.push(cb, callbackName), ret),
initiated: (cb: Callback<Transaction>, callbackName?: string) => (this.initiatedCallbacks.push(cb, callbackName), ret),
pending: (cb: Callback<Transaction>, callbackName?: string) => (this.pendingCallbacks.push(cb, callbackName), ret),
finished: (cb: Callback<Transaction>, callbackName?: string) => (this.finishedCallbacks.push(cb, callbackName), ret),
verified: (cb: Callback<VerifiedReceipt>, callbackName?: string) => (this.verifiedCallbacks.push(cb, callbackName), ret),
unverified: (cb: Callback<UnverifiedReceipt>, callbackName?: string) => (this.unverifiedCallbacks.push(cb, callbackName), ret),
receiptsReady: (cb: Callback<void>, callbackName?: string) => (this.receiptsReadyCallbacks.push(cb, callbackName), ret),
receiptsVerified: (cb: Callback<void>, callbackName?: string) => (this.receiptsVerifiedCallbacks.push(cb, callbackName), ret),
     */

    this.store.when()
      .productUpdated(() => {
        this.updateState({
          products: this.store.products,
          ...this.stateUpdates(),
        });
      })
      .receiptsReady(() => {
        this.updateState({
          transactions: this.store.localTransactions,
          ...this.stateUpdates(),
        });
      })
      .receiptUpdated(receipt => {
        this.updateState({
          transactions: this.store.localTransactions,
          ...this.stateUpdates(),
        });
      })
      .approved(transaction => {
        this.saveSubscriptionChange(transaction);

        this.updateState({
          isVerifying: true,
          ...this.stateUpdates(),
        });

        transaction.verify()
         .then(err => this.store.log.error(JSON.stringify(err)));
      })
      .verified(receipt => {

        this.updateState({
          purchases: this.store.verifiedPurchases,
          isVerifying: false,
          ...this.stateUpdates(),
        });

        this.saveSubscriptionChange(receipt);

        receipt.finish()
         .then(err => this.store.log.error(JSON.stringify(err)));
      })
      .unverified(unverified => {
        this.updateState({
          isProcessingOrder: false,
          isVerifying: false,
          ...this.stateUpdates(),
        });
      })
      .finished(transaction => {
        //if you have a spinner/loader or some state that
        //shows the user something is happening you can disable it here

        this.updateState({
          isProcessingOrder: false,
          isVerifying: false,
          ...this.stateUpdates(),
        });

        this.saveSubscriptionChange(transaction);

        console.error(this._stateUpdates.getValue())
      });
  }

  private stateUpdates(): Partial<State> {

    // subscription purchases sorted by expiry date
    const sortedSubscriptions = this.store.verifiedPurchases
      .filter(purchase => {
        const product = this.store.get(purchase.id, purchase.platform);
        return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION;
      })
      .sort((a, b) => (a.expiryDate ?? a.purchaseDate ?? 0) - (b.expiryDate ?? b.purchaseDate ?? 0));

    // active one
    const activeSubscription = this.store.verifiedPurchases.find(purchase => {
      const product = this.store.get(purchase.id, purchase.platform);
      return product?.type === CdvPurchase.ProductType.PAID_SUBSCRIPTION && product.owned;
    });

    // no active one, show info about the expired one
    const expiredSubscription = activeSubscription ? undefined : sortedSubscriptions.slice(-1)[0];

    return { activeSubscription, expiredSubscription };
  }

  update() {
    this.updateState({ isRefreshing: true });

    this.store.update()
      .then(() => {
        this.updateState({ isRefreshing: false });
      });
  }

  restorePurchases() {
    this.updateState({ isRefreshing: true });

    this.store.restorePurchases()
      .then(() => {
        this.updateState({ isRefreshing: false });

      });
  }

  saveSubscriptionChange(data: any): any {
    return this.apiService.post('/api/shop/customer/renew_subscription', {
      'data': data,
    });
  }

  manageSubscriptions(): any {
    return this.store.manageSubscriptions();
  }

  manageBilling(): any {
    return this.store.manageBilling();
  }

  startBarionCheckout() {

    if( ! this.barion) {
      return;
    }

    return this.barion.subscribe(1);
  }

}
