<div class="bubble-inner__item right-bubble"
 [ngClass]="{
    'status-active': status === 'active',
    'status-inactive': status === 'inactive',
    'status-completed': status === 'completed',
    'status-finished': status === 'finished',
    'status-postponed': status === 'postponed',
 }"
>
  <div *ngIf="false" class="counter-bubble">
    <span class="counter">{{readyOf}}/{{readyFrom}}</span>
  </div>
  <div class="ready-text" [ngSwitch]="status">
    <span  *ngSwitchCase="'finished'">Befejezett</span>
    <span  *ngSwitchCase="'inactive'">Inaktív</span>
    <span  *ngSwitchCase="'active'">Aktív</span>
    <span  *ngSwitchCase="'postponed'">Elhalasztott</span>
    <span  *ngSwitchCase="'completed'">Teljesített</span>
  </div>
</div>
