import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control: FormControl = new FormControl();
  @Input() autocapitalize: 'off' | 'on';
  @Input() type: string;
  @Input() icon: any;

  constructor() {}

  ngOnInit() {
    if (!this.type) {
      this.type = 'text';
    }
    if(this.type === 'password' || this.type === 'email') {
      this.autocapitalize = 'off';
    }
  }
}
