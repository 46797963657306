import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizAnswer} from "~/src/app/shared/models/quizTypes";

@Component({
  selector: 'app-answer-select',
  templateUrl: './answer-select.component.html',
  styleUrls: ['./answer-select.component.scss'],
})
export class AnswerSelectComponent  implements OnInit {
  @Input() answers: QuizAnswer[];
  @Input() answerClickedColorSetting: any;
  @Output() nextEmitter: EventEmitter<any> = new EventEmitter<any>();

  answerCorrectionText = '';
  successAnswerText = '';
  stepNumber = 1;
  isCorrect: boolean | null = null;
  selectedAnswer: QuizAnswer | null = null;
  correctAnswer: QuizAnswer | null = null;

  constructor() { }

  ngOnInit() {
    this.correctAnswer = this.answers.find(a => a.isCorrect) as QuizAnswer;
  }

  optionChange(answerId: string | null) {
    if (!answerId) return;

    const answer = this.answers.find(a => a.id === +answerId) as QuizAnswer;

    this.answerCorrectionText = answer.wrongAnswerDesc ? answer.wrongAnswerDesc : '';
    this.successAnswerText = answer.correctAnswer ? answer.correctAnswer : '';
    this.selectedAnswer = answer;
    this.stepNumber = 2;
    this.isCorrect = answer.isCorrect;
  }

  next() {
    this.answers.map((a: QuizAnswer) => {
      a.isSelected = false;
      a.status = 'default';

      return a;
    });
    this.stepNumber = 1;
    this.nextEmitter.emit(this.isCorrect);
    this.isCorrect = null;
    this.selectedAnswer = null;
  }
}
