<div class="modal notification-settings-modal">
  <div class="modal-header text-bold">
    Emlékeztető testreszabása
  </div>

  <div class="modal-content">
    <div class="separator top-separator mt-20"></div>
    <h2>
      MIRŐL KAPJAM?
    </h2>
    <div class="row"  >
      <div class="left" (click)="toggleReminderByName('mainSessionsReminder')">
        Feladat emlékeztető
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="mainSessionsReminder" (changedEmitter)="checked('mainSessionsReminder', $event)"></app-checkbox-toggle>
      </div>
    </div>
    <div class="row"  >
      <div class="left" (click)="toggleReminderByName('tripTicks')">
        Tippek, trükkök
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="tripTicks" (changedEmitter)="checked('tripTicks', $event)"></app-checkbox-toggle>
      </div>
    </div>
    <div class="row"  >
      <div class="left" (click)="toggleReminderByName('motivationTextReminder')">
        Motivációs üzenet
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="motivationTextReminder" (changedEmitter)="checked('motivationTextReminder', $event)"></app-checkbox-toggle>
      </div>
      <textarea *ngIf="motivationTextReminder" [(ngModel)]="motivationText" placeholder="Ha inkább saját motivációs üzenetet szeretnél, ide írhatod..."></textarea>
    </div>
    <div class="row"  >
      <div class="left" (click)="toggleReminderByName('weeklyProgressReportReminder')">
        Heti haladási riport
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="weeklyProgressReportReminder" (changedEmitter)="checked('weeklyProgressReportReminder', $event)"></app-checkbox-toggle>
      </div>
    </div>
    <div class="separator"></div>
    <h2>MIKOR KAPJAM?</h2>
    <div class="row add-reminder-container">
      <div class="row"  *ngFor="let reminder of reminders; let i = index;">
        <div class="left">
          <app-icon (click)="deleteReminderByIndex(i)" class="reminder-icon" [iconName]="'trash.svg'"></app-icon>
          {{ i + 1 }}. {{reminder.title}}
        </div>
        <div class="right">
        <span class="bold">
           {{reminder.time}}
        </span>
        </div>
      </div>
      <div class="row add-reminder" >
        <div class="left clickable" (click)="addReminder()">
          <app-icon class="reminder-icon add-reminder-icon" [iconName]="'plus-rounded.svg'"></app-icon>
          Új hozzáadása
        </div>
        <div class="right">
          <input class="timer-input" type="number" min="0" max="23" [(ngModel)]="reminderHours" (ngModelChange)="changeHours()"> :
          <input class="timer-input" type="number" min="0" max="59" [(ngModel)]="reminderMins" (ngModelChange)="changeMins()">
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="row" >
      <h2>HOL KAPJAM?</h2>
    </div>
    <div class="row"  >
      <div class="left" (click)="toggleReminderByName('pushReminder')">
        Push értesítések
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="pushReminder" (changedEmitter)="checked('pushReminder', $event)"></app-checkbox-toggle>
      </div>
    </div>
    <div class="row"  >
      <div class="left" (click)="toggleReminderByName('emailReminder')">
        Email
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="emailReminder" (changedEmitter)="checked('emailReminder', $event)"></app-checkbox-toggle>
      </div>
    </div>
    <div class="row" *ngIf="false">
      <div class="left" (click)="toggleReminderByName('calendarReminder')">
        Naptár bejegyzés
      </div>
      <div class="right">
        <app-checkbox-toggle [checked]="calendarReminder" (changedEmitter)="checked('calendarReminder', $event)"></app-checkbox-toggle>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-button class="save-button" (click)="save()">
      OK
    </app-button>
  </div>
</div>
