
<ion-label class="w-100 input-label" *ngIf="type === 'text' || type === 'password'">
    {{label}}
</ion-label>
<ion-input class="custom-input w-100"
           *ngIf="type === 'text' || type === 'password'"
           [ngClass]="{'hasIcon': icon}"
           [autocapitalize]="autocapitalize"
           [placeholder]="placeholder"
           [type]="type"
           [formControl]="control"
>
    <ion-icon *ngIf="icon" slot="end" name="search-outline"></ion-icon>
</ion-input>


<ion-grid *ngIf="type === 'checkbox'">
    <ion-row >
        <ion-col size="12">
            <ion-checkbox
                    style="margin: 0"
                    [ngClass]="{'error': control.errors?.mustBeTrue}"
                    [formControl]="control"
                    [labelPlacement]="'end'"
            >
                {{ label }}
            </ion-checkbox>
        </ion-col>
    </ion-row>
</ion-grid>

