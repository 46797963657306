<div
  (click)="open()" tappable cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  [ngClass]="{'is-favorite': isFavorite, 'is-bigger-height': isBiggerHeight}"
   class="list-item-container shadow-basic">
    <span class="counter" [ngStyle]="{'color': color}">
      {{ counter }}.
    </span>
    <div class="favorite" *ngIf="isFavorite">
      <app-icon [iconName]="'favorite.svg'"></app-icon>
    </div>
    <div class="list-item-text-container">
        <div class="list-item-text">
            {{ text }}
        </div>
    </div>
    <svg class="read-pipe" *ngIf="tip?.progression?.status === 'read'" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.2307" cy="10" r="10" fill="#7AC2B1"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5445 7.41421L14.1303 6L8.47354 11.6567L5.64493 8.82812L4.23071 10.2423L7.05933 13.071L7.05921 13.0711L8.47342 14.4853L15.5445 7.41421Z" fill="white"/>
    </svg>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isModalShown"
>
  <app-modal
    [type]="'link-list-item'"
    (closeEmitter)="closePopup()"
  >
    <div class="modal-content">
      <div>

        <span *ngIf="!isHtml">
          {{ longtext }}
        </span>
        <span *ngIf="isHtml">
          <span [innerHTML]="longtext"></span>
        </span>

        <span class="counter modal-counter" [ngStyle]="{'color': color}">
        {{ counter }}.
      </span>
      </div>
    </div>
    <div class="favorite-icon-container">
      <app-icon *ngIf="!isFavorite" (click)="setFavorite()" tappable [iconName]="'star-card-empty.svg'"></app-icon>
      <app-icon *ngIf="isFavorite"  (click)="setFavorite()" tappable [iconName]="'star-card-full.svg'"></app-icon>
    </div>
  </app-modal>
</ng-template>
