import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Video} from "~/src/app/shared/components/video/video.component";


@Component({
  selector: 'app-recommended-challenges',
  templateUrl: './recommended-challenges.component.html',
  styleUrls: ['./recommended-challenges.component.scss'],
})
export class RecommendedChallengesComponent  implements OnInit {
  @Input() title!: string;
  @Input() url!: string;
  @Input() video !: Video;
  @Output() detailsClickEvent = new EventEmitter<string>();


  constructor() { }

  ngOnInit() {}

  detailsClick() {
    this.detailsClickEvent.emit();
  }
}
