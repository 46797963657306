<div class="challenges">
  <h3 class="main-title font-xxl-large-size fw-800 uppercase">Összes kihívás</h3>
  <div
       class="evolution-container d-flex-column gap-10"
  >
    <ng-container *ngFor="let course of courses">
      <app-list-challenge-item
        [item]="course"
        [mode]="'challenge'"
      ></app-list-challenge-item>
    </ng-container>
  </div>
</div>
