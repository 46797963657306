import { Injectable } from '@angular/core';
import {ApiService} from "~/src/app/core/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {
  private cacheData: null | any = null;

  constructor(
    private apiService: ApiService,
  ) { }

  setFavorite(contentType: string, contentId: number) {
    return this.apiService.post('/favorites', {
      "content_type": contentType,
      "content_id": contentId
    });
  }

  deleteFavorite(id: number) {
    return this.apiService.delete('/favorites/' + id);
  }
  getFavoritesByContentType(contentType: string | null, isIncludeContent = false) {
    const include =  isIncludeContent ? '&include=content' : '';

    return this.apiService.get('/favorites?filters[content_type]=' + contentType + include);
  }
}
