import { Component, Input, OnInit } from '@angular/core';
import { ChildrenDto } from "~/src/app/shared/models/childrenDto";

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent  implements OnInit {
  @Input() children: ChildrenDto[];

  constructor() { }

  ngOnInit() {}

  selectItem(i: number) {
    this.children.map(c => c.isSelected = false)
    this.children[i].isSelected = true;
  }
}
