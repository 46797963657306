<div class="challenges">
  <h3 class="main-title font-xxl-large-size fw-800 uppercase">Összes kihívás</h3>
    <div *ngFor="let competence of competencies"
         class="evolution-container d-flex-column gap-10"
    >
      <h3 class="font-large-size blue-dark fw-800 uppercase">
        {{ competence.name }}
      </h3>
      <ng-container *ngFor="let course of competence.courses">
        <app-list-challenge-item
          [item]="course"
          [mode]="'challenge'"
        ></app-list-challenge-item>
      </ng-container>
  </div>
</div>
