import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-level',
  templateUrl: './account-level.component.html',
  styleUrls: ['./account-level.component.scss'],
})

export class AccountLevelComponent  implements OnInit {
  @Input() percent: number;

  constructor() { }

  ngOnInit() {}
}
