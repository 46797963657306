<div
  (click)="open()" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  [ngClass]="{'is-favorite': isFavorite, 'is-inactive': isBlurOnInactiveFeature && quiz.progression?.status === 'inactive'}"
  class="w-100 quiz-item test-your-brain shadow-basic clickable">
  <div class="test-your-brain-inner">
    <div class="favorite" *ngIf="isFavorite">
      <app-icon [iconName]="'favorite.svg'"></app-icon>
    </div>

    <div class="test-your-brain-question-icon">
      <svg width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
          <path d="M11.4 25.465L10.944 14.521H13.395C14.345 14.521 15.162 14.35 15.846 14.008C16.568 13.666 17.119 13.191 17.499 12.583C17.917 11.937 18.126 11.177 18.126 10.303C18.126 9.467 17.936 8.745 17.556 8.137C17.176 7.491 16.644 7.016 15.96 6.712C15.276 6.37 14.478 6.199 13.566 6.199C12.16 6.199 10.868 6.617 9.69 7.453C8.55 8.251 7.619 9.353 6.897 10.759L1.311 5.515C2.451 3.273 4.104 1.468 6.27 0.0999973C8.436 -1.306 11.001 -2.009 13.965 -2.009C16.435 -2.009 18.62 -1.496 20.52 -0.470002C22.42 0.555998 23.902 1.962 24.966 3.748C26.03 5.496 26.562 7.472 26.562 9.676C26.562 11.918 26.106 13.837 25.194 15.433C24.282 17.029 23.047 18.34 21.489 19.366C19.931 20.354 18.164 21.057 16.188 21.475L18.753 18.112L18.24 25.465H11.4ZM14.82 40.627C13.3 40.627 12.046 40.114 11.058 39.088C10.108 38.062 9.633 36.827 9.633 35.383C9.633 33.863 10.108 32.609 11.058 31.621C12.046 30.595 13.3 30.082 14.82 30.082C16.302 30.082 17.518 30.595 18.468 31.621C19.456 32.609 19.95 33.863 19.95 35.383C19.95 36.827 19.456 38.062 18.468 39.088C17.518 40.114 16.302 40.627 14.82 40.627Z" fill="#EFC376"/>
          <path d="M0 47.125H28.443V49.975H0V47.125Z" fill="#EFC376"/>
        </g>
      </svg>
    </div>
    <div class="test-your-brain-text">{{ title }}</div>
    <div class="test-your-brain-heavy-mark-icon">
      <svg *ngIf="progressionStatus === 'completed_successful'" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10.2307" cy="10" r="10" fill="#7AC2B1"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5445 7.41421L14.1303 6L8.47354 11.6567L5.64493 8.82812L4.23071 10.2423L7.05933 13.071L7.05921 13.0711L8.47342 14.4853L15.5445 7.41421Z" fill="white"/>
      </svg>

      <svg *ngIf="progressionStatus === 'completed_failed'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#C95B32"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5425 14.4858L14.4853 13.543L11.186 10.2436L14.4854 6.9441L13.5426 6.00129L10.2432 9.30077L6.94288 6.00049L6.00007 6.9433L9.30035 10.2436L6.00017 13.5438L6.94298 14.4866L10.2432 11.1864L13.5425 14.4858Z" fill="white"/>
      </svg>


    </div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isModalShown"
>
  <app-modal
    [type]="'quiz'"
    (closeEmitter)="isModalShown = !isModalShown"
    [isTransparent]="true"
    [isFullWidth]="true"
  >
    <app-quiz
      [type]="type"
      [quizType]="quizType"
      [quiz]="quiz"
      [parentData]="parentData"
      (closeEmitter)="isModalShown = !isModalShown"
      (isSuccessEmitter)="quizCompleted($event)"
      (isWrongEmitter)="isWrongEmitter($event)"
      (isFilledEmitter)="filledEmit($event)"
    ></app-quiz>
    <div class="favorite-icon-container" *ngIf="false">
      <app-icon *ngIf="!isFavorite" (click)="setFavorite()" [iconName]="'star-card-empty.svg'"></app-icon>
      <app-icon *ngIf="isFavorite"  (click)="setFavorite()" [iconName]="'star-card-full.svg'"></app-icon>
    </div>
  </app-modal>

</ng-template>
