import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() options: {id: number; title: string;}[] = [];
  @Input() selectedOption: null | number = null;
  @Output() optionChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {}

  onOptionChange(event: any): void {
    const selectedValue = event.target.value;
    this.selectedOption = selectedValue;
    this.optionChange.emit(selectedValue);
  }

  toggleSelect(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    // @ts-ignore
    const select = event.target.closest('.custom-select-container').querySelector('.custom-select');
    if (select) {
      select.dispatchEvent(new Event('click'));
    }
  }
}
