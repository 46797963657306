import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from "~/src/app/core/services/user.service";
import {NavController, Platform} from "@ionic/angular";
import {ToastService} from "~/src/app/core/services/toast-service.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent  implements OnInit {
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter<any>();

  mainSessionsReminder = false;
  tripTicks = false;
  weeklyProgressReportReminder = false;
  motivationTextReminder = false;
  partnerActivity = false;
  progressReport = false;
  pushReminder = false;
  emailReminder = false;
  calendarReminder = false;
  motivationText: string;
  reminders: any[] = [];
  reminderHours = 0;
  reminderMins = 0;

  private profile: any;

  constructor(
    private userSevice: UserService,
    public navCtrl: NavController,
    private toastr: ToastService,
    private platform: Platform
  ) {
    // this.isWeb = this.platform.is('pwa') || this.platform.is('mobileweb') || this.platform.is('desktop');
  }

  ngOnInit() {
    this.userSevice.getProfile().subscribe((resp: any) => {
      this.profile = resp.data;
      this.mainSessionsReminder = this.profile.n_en_type_practise_session === '1';
      this.tripTicks = this.profile.n_en_type_hint === '1';
      this.weeklyProgressReportReminder = this.profile.n_en_type_weekly_progress_report === '1';
      this.pushReminder = this.profile.n_en_via_push === '1';
      this.emailReminder = this.profile.n_en_via_email === '1';
      this.calendarReminder = this.profile.n_en_via_calendar === '1';
      this.motivationTextReminder = this.profile.n_en_type_motivational_message === '1';
      this.motivationText = this.profile.custom_motivational_quote;
      this.partnerActivity =
      this.progressReport = this.profile.progress_report === '1';
      this.reminders = this.profile.notification_dates ? JSON.parse(this.profile.notification_dates).map((time: string) => {
        return {
          time,
          title: 'Emlékeztető'
        };
      }) : [];
    });
  }

  toggleReminderByName(propertyName: string) {
    // @ts-ignore
    this[propertyName] = !this[propertyName];
  }

  checked(propertyName: string, $event: boolean) {
    // @ts-ignore
    this[propertyName] = $event;
  }

  addReminder() {
    const timeString = (this.reminderHours < 10 ? '0' + this.reminderHours : this.reminderHours) + ':' + (this.reminderMins < 10 ? '0' + this.reminderMins : this.reminderMins);

    if (this.reminders.find(r => r.time === timeString)) {
      return;
    }

    this.reminders.push({
      time: timeString,
      title: 'Emlékeztető'
    })
  }

  changeHours() {
    if (this.reminderHours > 23) {
      this.reminderHours = 23;
    }

    if (this.reminderHours < 0) {
      this.reminderHours = 0;
    }

    if (isNaN(this.reminderHours)) {
      this.reminderHours = 0;
    }
  }

  changeMins() {
    if (this.reminderMins > 59) {
      this.reminderMins = 59;
    }

    if (this.reminderMins < 0) {
      this.reminderMins = 0;
    }

    if (isNaN(this.reminderMins)) {
      this.reminderMins = 0;
    }
  }

  save() {
    const platform = this.platform.is('android') ? 'android' : this.platform.is('ios') ? 'ios' : 'web';

    const profile = {
      "username": this.profile.username,
      "firstname": this.profile.firstname,
      "lastname": this.profile.lastname,
      "email": this.profile.email,
      "phone": this.profile.phone,
      "marketing_subscribe": this.profile.marketing_subscribe,
      "firebase_token": this.profile.firebase_token,
      "device_platform": platform,
      "n_en_type_practise_session": this.mainSessionsReminder ? 1 : 0,
      "n_en_type_hint": this.tripTicks ? 1 : 0,
      "n_en_type_motivational_message": this.motivationTextReminder ? 1 : 0,
      "custom_motivational_quote": this.motivationText,
      "n_en_type_weekly_progress_report": this.weeklyProgressReportReminder ? 1 : 0,
      "n_en_via_push": this.pushReminder ? 1 : 0,
      "n_en_via_email": this.emailReminder ? 1 : 0,
      "n_en_via_calendar": this.calendarReminder ? 1 : 0,
      "is_subscribed": this.profile.is_subscribed,
      "notification_dates": this.reminders.length ? JSON.stringify(this.reminders.map(r => r.time)) : '[]',
    };

    this.userSevice.updateNotificationSettings(profile).subscribe(() => {
      this.closeEmitter.emit(true);
    }, error => {
      this.toastr.openToast('Hiba történt a mentés során.');
    });
  }

  deleteReminderByIndex(i: number) {
    this.reminders.splice(i, 1);
  }
}
