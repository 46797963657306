import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizAnswer} from "~/src/app/shared/models/quizTypes";

@Component({
  selector: 'app-answer-list',
  templateUrl: './answer-list.component.html',
  styleUrls: ['./answer-list.component.scss'],
})
export class AnswerListComponent  implements OnInit {
  @Input() answers: QuizAnswer[];
  @Input() answerClickedColorSetting: any;
  @Output() nextEmitter: EventEmitter<any> = new EventEmitter<any>();

  answerCorrectionText = '';
  successAnswerText = '';
  stepNumber = 1;
  isCorrect: boolean | null = null;
  selectedAnswer: QuizAnswer | null = null;
  correctAnswer: QuizAnswer | null = null;

  constructor() { }

  ngOnInit() {}

  handleCountdownFinished(answer: QuizAnswer) {
    this.selectedAnswer = answer;
    this.stepNumber = 2;
    this.isCorrect = answer.isCorrect;
  }

  clickAnswer(answer: QuizAnswer) {
    this.answers.map((a: QuizAnswer) => {
      a.isSelected = a.id === answer.id;
      a.isAnswered = a.id === answer.id;
      a.status = a.id === answer.id ? 'selected' : 'default';

      if (a.id === answer.id) {
        this.answerCorrectionText = a.wrongAnswerDesc ? a.wrongAnswerDesc : '';
        this.successAnswerText = a.correctAnswer ? a.correctAnswer : '';
      }
      if (a.isCorrect) {
        this.correctAnswer = a;
      }

      return a;
    });
  }

  next(): void {
    this.answers.map((a: QuizAnswer) => {
      a.isSelected = false;
      a.status = 'default';

      return a;
    });
    this.stepNumber = 1;
    this.nextEmitter.emit(this.isCorrect);
    this.isCorrect = null;
    this.selectedAnswer = null;
  }
}
