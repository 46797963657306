import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizAnswer} from "~/src/app/shared/models/quizTypes";
import {
  CdkDragDrop,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-answer-drag-and-drop',
  templateUrl: './answer-drag-and-drop.component.html',
  styleUrls: ['./answer-drag-and-drop.component.scss'],
})
export class AnswerDragAndDropComponent  implements OnInit {
  @Input() answers: QuizAnswer[];
  @Input() answerClickedColorSetting: any;
  @Output() nextEmitter: EventEmitter<any> = new EventEmitter<any>();

  answerCorrectionText = '';
  successAnswerText = '';
  stepNumber = 1;
  isCorrect: boolean | null = null;
  selectedAnswer: QuizAnswer | null = null;
  correctAnswer: QuizAnswer | null = null;
  originalAnswers: QuizAnswer[];

  fakeItems = ['Carrots', 'Tomatoes'];
  isDropped: boolean;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.correctAnswer = this.answers.find(a => a.isCorrect) as QuizAnswer;
    this.originalAnswers = Object.assign([], this.answers);
  }

  next() {
    this.answers = Object.assign([], this.originalAnswers);
    this.answers.map((a: QuizAnswer) => {
      a.isSelected = false;
      a.status = 'default';

      return a;
    });
    this.stepNumber = 1;
    this.nextEmitter.emit(this.isCorrect);
    this.isCorrect = null;
    this.selectedAnswer = null;
    this.cdr.detectChanges();
  }



  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      this.selectedAnswer = event.item.data as any;
      this.isDropped = true;
      this.cdr.detectChanges();
    } else {
      this.answers = Object.assign([], this.originalAnswers);
      this.isDropped = false;
      this.cdr.detectChanges();
    }
  }

  handleCountdownFinished() {
    this.stepNumber = 2;
    this.isCorrect = this.selectedAnswer && this.selectedAnswer.isCorrect;

    this.answerCorrectionText = this.selectedAnswer?.wrongAnswerDesc ? this.selectedAnswer.wrongAnswerDesc : '';
    this.successAnswerText = this.selectedAnswer?.correctAnswer ? this.selectedAnswer.correctAnswer : '';

    this.cdr.detectChanges();
  }

  dragStarted() {
    this.answers = Object.assign([], this.originalAnswers);
    this.isDropped = false;
    this.cdr.detectChanges();
  }

  dragDropped(index: number) {
    this.answers.splice(index, 1);
    this.cdr.detectChanges();
  }
}
