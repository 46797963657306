import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuizAnswer} from "~/src/app/shared/models/quizTypes";

@Component({
  selector: 'app-answer-text',
  templateUrl: './answer-text.component.html',
  styleUrls: ['./answer-text.component.scss'],
})
export class AnswerTextComponent  implements OnInit {
  @Input() answers: QuizAnswer[];
  @Input() quiz: any;
  @Input() answerClickedColorSetting: any;
  @Input() answerCorrectionText = '';
  @Input() successAnswerText = '';
  @Output() nextEmitter: EventEmitter<any> = new EventEmitter<any>();

  stepNumber = 1;
  answer = '';
  isCorrect: boolean | null = null;
  selectedAnswer: QuizAnswer | null = null;
  correctAnswer: QuizAnswer | null = null;
  minCaracterNumber = 50;

  constructor() { }

  ngOnInit() {
    this.minCaracterNumber = this.answers[0].minCaracterNumber || 50;
  }

  handleCountdownFinished(answer: QuizAnswer) {
    this.selectedAnswer = answer;
    this.stepNumber = 2;
    this.isCorrect = answer.isCorrect;
  }

  next() {
    if (this.answer.length) {
      this.nextEmitter.emit(true);
      this.answer = '';
    }
  }
}
