<div class="swipe-paginator-container">
  <div class="swipe-paginator-element"
       [ngClass]="{'lot-of-element': numberOfElement >= numberOfChangeDesignToMiniViewForLotOfElement}"
       *ngFor="let element of elements; let i = index;"
       (click)="pageClick(i)" tappable
  >
    <app-icon class="swipe-paginator-element-icon" *ngIf="i !== activeElementIndex" [iconName]="'swipe-paginator.svg'"></app-icon>
    <app-icon class="swipe-paginator-element-icon" *ngIf="i === activeElementIndex" [iconName]="'swipe-paginator-checked.svg'"></app-icon>
  </div>
</div>
