<div *ngIf="stepNumber === 1">
  <textarea [minLength]="minCaracterNumber"  [(ngModel)]="answer" placeholder="Ird ide a válaszodat."></textarea>
  <div class="remain-caracters-counter">
    Még legalább ennyi karaktert írj be: {{ answer.length }} / {{ minCaracterNumber }}
  </div>
  <div class="quiz-actions mt-50">
    <div class="separator"></div>
    <app-button [disabled]="answer.length < minCaracterNumber" (click)="next()">
      Következő
    </app-button>
  </div>
</div>
