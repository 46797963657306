<div class="notification d-flex" [routerLink]="item.routerLink" (click)="showModal()">
  <div *ngIf="type === 'quiz'" class="icon-container d-flex-center">
    <app-icon
      *ngIf="item.status === 'completed_successful' || item.status === 'completed_finished'"
      [iconName]="'done.svg'"
    ></app-icon>
    <svg *ngIf="item.status === 'completed_failed'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#C95B32"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5425 14.4858L14.4853 13.543L11.186 10.2436L14.4854 6.9441L13.5426 6.00129L10.2432 9.30077L6.94288 6.00049L6.00007 6.9433L9.30035 10.2436L6.00017 13.5438L6.94298 14.4866L10.2432 11.1864L13.5425 14.4858Z" fill="white"/>
    </svg>
    <app-icon *ngIf="item.status !== 'completed_failed' && item.status !== 'completed_finished' && item.status !== 'completed_successful'" [iconName]="'untouched.svg'"></app-icon>
  </div>

  <div *ngIf="type !== 'quiz'" class="icon-container d-flex-center">
    <app-icon *ngIf="item.isViewed" [iconName]="'done.svg'"></app-icon>
    <app-icon *ngIf="!item.isViewed" [iconName]="'untouched.svg'"></app-icon>
  </div>
  <div class="text d-flex-center ">
    {{ item.title }}
  </div>
</div>
<div>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOpen]="isModalShown"
  >
    <app-modal
      [type]="'info-popup'"
      (closeEmitter)="close()"
      *ngIf="isModalShown"
    >
      <div class="modal-content mt--25">
        <h3 class="pb-20 pr-20 practiceSession-title">
          {{ item.title }}
        </h3>

        <span [innerHTML]="item.body"></span>
      </div>
    </app-modal>
  </ng-template>
</div>
