<div class="icon-container">
  <img src="./assets/icon/level.svg" alt="">
</div>
<div class="content d-flex-column">
  <p class="font-small-size fw-400 white">szinted</p>
  <h3 class="font-large-size fw-700 mb-4 white">Fekete öves szűlőzseni</h3>
  <div class="action-container d-flex gap-10">
    <div class="percentage-container">
      <div class="percent-bar bg-orange m-2" [style.width.%]="percent"></div>
      <div class="percent-bar bg- m-2" [style.width.%]="percent"></div>
    </div>
    <p class="font-small-size fw-700 white">540/800</p>
  </div>
</div>
