import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-readiness-indicator',
  templateUrl: './readiness-indicator.component.html',
  styleUrls: ['./readiness-indicator.component.scss'],
})
export class ReadinessIndicatorComponent  implements OnInit {
  @Input({ required: true }) readyOf !: number;
  @Input({ required: true }) readyFrom !: number;
  @Input({ required: true }) status: 'inactive' | 'active' | 'completed' | 'finished' | 'postponed' | '' = '';

  constructor() { }

  ngOnInit() {}
}
