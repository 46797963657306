<div *ngIf="stepNumber === 1">
  <app-select
    [options]="answers"
    (optionChange)="optionChange($event)"
  ></app-select>
</div>
<div *ngIf="stepNumber === 2">
  <div
    class="quiz-answer shadow-basic d-flex "
    style="background: {{ answerClickedColorSetting.goodAnswer.backgroundColor }}"
  >
    <div class="answer-title d-flex-center"
         style="color: {{ answerClickedColorSetting.goodAnswer.textColor }}"
    >
      {{ correctAnswer.title }}
    </div>
  </div>
  <div *ngIf="isCorrect === false">
    <div
      class="quiz-answer shadow-basic d-flex "
      style="background: {{ answerClickedColorSetting.wrongAnswer.backgroundColor }}"
    >
      <div class="answer-title d-flex-center"
           style="color: {{ answerClickedColorSetting.wrongAnswer.textColor }}"
      >
        {{ selectedAnswer.title }}
      </div>
    </div>
  </div>

  <div *ngIf="isCorrect === false" class="correction">
    {{ answerCorrectionText }}
  </div>
  <div *ngIf="isCorrect === true" class="correction">
    {{ successAnswerText }}
  </div>


  <div class="quiz-actions">
    <div class="separator"></div>
    <app-button (click)="next()">
      Következő
    </app-button>
  </div>
</div>
