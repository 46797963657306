<app-widget-container
  class="shadow-basic"
  [title]="title"
  [color]="'primary'"
  [hasRightBubble]="true"
  [from]="from"
  [to]="to"
  [link]="'/pages/challenge/' + activePractiseSessionsResult.id"
>
  <div class="bubble-inner {{ !last ? 'mb-10' : '' }}" *ngFor="let practiceSessionResults of practiceSessionsResults; let last = last">
    <div class="bubble-inner__item" [routerLink]="'/pages/challenge/' + activePractiseSessionsResult.id + '/' + practiceSessionResults.position">
      <ion-row>
      <span class="title">
        {{ practiceSessionResults.title }}
      </span>
      </ion-row>
      <ion-row class="course-practise-progression-indicator" style="display: flex; justify-content: space-between;">
        <div style="width: {{ (100 / practiceSessionResults.quiz_results.length) - 3 }}%" [ngClass]="quizResultStatusClassFormatter(quizResult.progression_status)" *ngFor="let quizResult of practiceSessionResults.quiz_results"></div>
      </ion-row>
    </div>
  </div>
</app-widget-container>
