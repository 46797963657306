<div>
  <p class="blue-dark fw-400 font-size ">
    Ha elutazol, vagy bármilyen más élethelyzet miatt nem tudod éppen folytatni a gyakorlást, akkor szüneteltetheted azt.
    Ezzel az apparently tagságod aktív marad, csupán a soron következő gyakorló leckéket és az emlékeztetőket szünetelteted, hogy azok ne torlódjanak fel.
    Ugyanitt tudod folytatni a gyakorlást.
  </p>
  <app-button *ngIf="false" (click)="suspendOrUnsuspend()" tappable [color]="isSuspended === true ? 'secondary': 'primary' ">
    {{ button }}
  </app-button>

  <div class="checkbox-toggle-container pt-20 pb-20">
    <div class="checkbox-toggle-container-left">
      {{ button }}
    </div>
    <div class="checkbox-toggle-container-right">
      <app-checkbox-toggle class="suspend-toggle" [checked]="isSuspended ||
      false" (changedEmitter)="suspendOrUnsuspend()"></app-checkbox-toggle>
    </div>
  </div>


</div>
