import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivateYourAccountComponent} from './activate-your-account/activate-your-account.component';
import {LandingPagesRoutingModule} from "src/app/core/modules/landing-pages/landing-pages-routing.module";

@NgModule({
  imports: [
    CommonModule,
    LandingPagesRoutingModule,
  ],
  exports: [LandingPagesRoutingModule],
  declarations: [ActivateYourAccountComponent]
})
export class LandingPagesModule {}
