import {Component, Input, OnInit} from '@angular/core';
import {ActivityLogService} from "~/src/app/shared/services/activitylog.service";

@Component({
  selector: 'app-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
})
export class NotificationListItemComponent  implements OnInit {
  @Input() item: {
    body: any;
    title: string;
    isViewed: boolean;
    routerLink: string;
    id: number;
    status: string;
  }
  @Input() type: 'quiz' | 'tip' | 'reminder' = 'reminder';
  isModalShown = false;

  constructor(
    private activityLogService: ActivityLogService
  ) { }

  ngOnInit() {
    this.item.isViewed = this.item.status === 'read';
  }

  showModal() {
    this.item.isViewed = true;
    this.activityLogService.remindersOpen(this.item.id).subscribe();
    if(this.item.routerLink) {
      return;
    }
    if(!this.item.body || !this.item.body.length) {
      return;
    }
    this.isModalShown = true;
  }

  close() {
    this.isModalShown = false;
  }
}
