import {
  Component,
  inject,
  Input,
  OnDestroy,
} from "@angular/core";
import {LoadingController, NavController} from "@ionic/angular";
import {addIcons} from "ionicons";
import {
  checkmarkCircle,
  peopleOutline,
} from "ionicons/icons";
import {PaymentService} from "src/app/shared/services/payment/payment.service";
import {UserService} from "src/app/core/services/user.service";
import {Capacitor} from "@capacitor/core";
import {State} from "~/src/app/pages/pages/payment/state";
import {BarionPaymentService} from "~/src/app/shared/services/payment/barion-payment.service";
import {SubscriptionData} from "~/src/app/shared/services/payment/subscription-data";

@Component({
  selector: 'app-subscription',
  template: `
    <div class="modal shadow-basic subsciption-modal" style="{{isSubscriptionActive ? 'padding-bottom: 0' : ''}}">
      <div class="content-container">
        <ion-icon class="subsciption-icon" [name]="iconName" [color]="iconColor"></ion-icon>

        <div class="w-100" *ngIf="isSubscriptionActive; else inactiveContent">
          Az apparently közösség teljes körű tagja vagy!
          <br>
          <span *ngIf="subscriptionDate && !isPaymentDisabled">
            Érvényesség: {{ subscriptionDate }}
          </span>
        </div>
        <ng-template #inactiveContent>
          <p>Még nem vagy az apparently közösség teljes körű tagja.</p>
        </ng-template>

        <ng-template #onlyShowDetailsButton>
          <app-button
            tappable
            class="text-center white w-100 mt-20"
          >Részletek</app-button>
          <div  class="pb-20"></div>
        </ng-template>

        <ng-container *ngIf="isSubscriptionActive && getPaymentPlatform() === 'ios-appstore'">

          <span class="w-100 mt-20">

            <app-button
              tappable
              (click)="manageSubscriptions()"
              class="text-center white w-100 mt-20"
              [color]="'secondary'"
            >Előfizetés módosítása</app-button>

          </span>

          <span class="w-100 mt-10">
            <app-button
              tappable
              class="text-center white w-100 mt-20"
              (click)="manageBilling()"
              [color]="'secondary'"
            >Számlázási adataim módosítása</app-button>

          </span>
        </ng-container>

        <ng-container *ngIf="!isSubscriptionActive && getPaymentPlatform() === 'barion'">

          <span class="w-100 mt-20">

            <app-button
              tappable
              (click)="startBarionCheckout()"
              class="text-center white w-100 mt-20"
              [color]="'secondary'"
            >BARION TESZT</app-button>

          </span>
        </ng-container>

        <ng-container class="w-100" *ngIf="!isOnlyShowDetailsButton; else onlyShowDetailsButton">

          <app-button
            *ngIf="!isSubscriptionActive && !isWeb"
            tappable
            class="text-center white w-100 mt-20"
          >Részletek</app-button>

          <span class="w-100 mt-10" *ngFor="let product of products; let first = first;">
            <app-button class="w-100" style="height: auto;" [color]="first ? 'secondary' : 'primary'" [disabled]=" ! product.canPurchase"
                        (click)="subscribe(product)" tappable>
              {{ product.title }} - {{ product.pricing.price }} {{ product.pricing.currency }}
              <p>{{ product.description }}</p>
            </app-button>
          </span>

        </ng-container>

      </div>
    </div>
  `,
  styles: [`
    .subsciption-modal {
      margin-bottom: 20px;
      margin-top: -10px;
    }
    .content-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    ion-card {
      max-width: 400px;
      margin: 20px auto;
    }
    .subsciption-icon {
      font-size: 45px;
      margin-bottom: 10px;
    }
    .modal {
      border-radius: 10px;
    }
    .price {
      font-size: var(--ion-font-size-small);
    }
  `]
})

export class SubscriptionComponent implements OnDestroy {

  @Input() isOnlyShowDetailsButton = false;
  isWeb;
  isUnsibscribePopupIsOpened = false;
  loader: any;

  isSubscriptionActive: boolean | null = null;
  subscriptionDate: string | null = null;
  products: any;
  isPaymentDisabled = localStorage.getItem('isPaymentDisabled');
  paymentServiceStateSubscription: any;


  constructor(
      private loadingCtrl: LoadingController,
      private navCtr: NavController,
      private paymentService: PaymentService = inject(PaymentService),
      private userSevice: UserService
  ) {
    addIcons({ checkmarkCircle, peopleOutline });

    const isNative = Capacitor.isNativePlatform();
    this.isWeb = !isNative;

    this.loadSubscriptionData();

    if(this.paymentService.paymentPlatform === CdvPurchase.Platform.APPLE_APPSTORE) {
      this.paymentServiceStateSubscription = this.paymentService.currentState$().subscribe({
        next: (state: State) => {
          this.products = state.products;

          this.isSubscriptionActive = !! state.activeSubscription;
          this.subscriptionDate = this.isSubscriptionActive && state.activeSubscription && state.activeSubscription.expiryDate ?
            (new Date(state.activeSubscription.expiryDate).toLocaleString()) : null;

          console.error(state);
        }
      });
    }

  }
  get iconName(): string {
    return this.isSubscriptionActive ? 'checkmark-circle' : 'people-outline';
  }

  get iconColor(): string {
    return this.isSubscriptionActive ? 'success' : '#2e5180';
  }

  private async loadSubscriptionData() {
    // const products = await this.paymentService.getAllproducts().toPromise();
    const subscriptionData = await this.userSevice.getSubscriptionData();
    console.error(subscriptionData);

    if(subscriptionData) {
      this.isSubscriptionActive = subscriptionData.isActive;
      this.subscriptionDate = subscriptionData.expirationDate;
    }
  }

  async ngOnDestroy() {
    if(this.paymentServiceStateSubscription) {
      this.paymentServiceStateSubscription.complete();
    }
  }

  openExternalLink() {
    this.navCtr.navigateForward('/pages/profile/subscription');
  }

  manageSubscriptions(): any {
    return this.paymentService.manageSubscriptions();
  }

  manageBilling(): any {
    return this.paymentService.manageBilling();
  }

  subscribe(product: CdvPurchase.Product) {
    return this.paymentService.subscribe(product.platform, product.id, product.offers[0].id);
  }

  getPaymentPlatform(): string {
    return this.paymentService.paymentPlatform || "";
  }

  async showLoading() {
    this.loader = await this.loadingCtrl.create({
      spinner: "dots",
      message: 'Betöltés...',
    });

    return this.loader.present();
  }

  async startBarionCheckout() {
    this.paymentService.startBarionCheckout()?.subscribe((res: any) => {
      console.error(res);
      location.href = res.redirect_url;
    });
  }

}
