
import {ApiService} from "src/app/core/services/api.service";
import {Observable} from "rxjs";
import {SubscriptionData} from "~/src/app/shared/services/payment/subscription-data";

export class BarionPaymentService {

  constructor(
      private apiService: ApiService
  ) { }


  subscribe(productId: number): Observable<SubscriptionData<any>> {
    return this.apiService.post('/payment', {
      'product_id': productId
    });
  }

  checkTransactionStatus(transactionId: number | string): Observable<SubscriptionData<any>> {
    return this.apiService.get(`/payment?transaction_id=${transactionId}`);
  }
  getAllproducts(): Observable<any> {
    return this.apiService.get('/products');
  }

  getSettings() {
    return this.apiService.get('/shop/products');
  }

  cancelSubscription() {
    return this.apiService.post('/shop/customer/cancel');
  }
}

/*
  Itt találod a Barion teszteléshez a bankkártyákat
  BIN: 4444 8888 8888 5559
  Expiration date: any future date
  CVC: any 3-digit number

 */
