import { Directive, ElementRef, HostListener } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "~/src/environments/environment";
@Directive({
  selector: '[appFileUpload]'
})
export class FilesUploadDirective {
  private uploadUrl = environment.api_url + '/files/upload';

  constructor(
    private elementRef: ElementRef,
    private http: HttpClient,
  ) { }

  @HostListener('change') onChange(): void {
    const inputElement: HTMLInputElement = this.elementRef.nativeElement;

    if (inputElement.files && inputElement.files.length) {
      const formData = new FormData();
      // @ts-ignore
      for (const file of inputElement.files) {
        formData.append('files', file, file.name);
      }
      this.upload(formData);
    }
  }

  private upload(formData: FormData): void {
     this.http.post(this.uploadUrl, formData).subscribe(response => {
       console.log('Sikeres feltöltés!', response);
     }, error => {
       console.error('Hiba történt a feltöltés során!', error);
     });
    console.log('Fájl(ok) elküldve a szerverre.');
  }
}
