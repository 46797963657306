import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import videojs from 'video.js';
import Player from "video.js/dist/types/player";

const videoPosterDefault =  'assets/video-poster-default.jpg';

export interface VideoJsOptions {
  width ?: string | number,
  height ?: string | number,
  fluid: boolean;
  aspectRatio ?: string;
  autoplay: boolean;
  sources?: {
    src: string;
    type: string;
  }[];
  controls?: boolean; // Mutassa-e a lejátszó vezérlőit
  poster?: string; // Egy kép elérési útvonala, amit a videó lejátszása előtt mutat beállítás esetén
  techOrder?: string[]; // A technológia sorrend, amelyet a lejátszó használjon. Például: ['html5', 'flash']
  plugins?: Record<string, any>; // Egy objektum, amely a beépülő modulok konfigurációját tartalmazza
  language?: string; // A lejátszó nyelve
  playbackRates?: number[]; // Tömb, amely tartalmazza az elérhető lejátszási sebességek listáját
  liveui?: boolean; // Ha igaz, akkor a live lejátszóhoz tartozó UI komponensek megjelennek
  html5?: {
    vhs?: {
      overrideNative?: boolean; // Ha igaz, akkor az alacsony szintű HLS lejátszó használatakor a beépített HLS támogatást használja
      withCredentials?: boolean; // Megadja, hogy a lejátszó automatikusan küldje-e a kéréseket a felhasználó hitelesítő adataival
    };
    nativeAudioTracks?: boolean; // Ha igaz, akkor a lejátszó használja a böngésző natív audio track kezelését
    nativeVideoTracks?: boolean; // Ha igaz, akkor a lejátszó használja a böngésző natív video track kezelését
  };
  disablePictureInPicture?: boolean; // Ha igaz, akkor a lejátszó nem támogatja a kép a képben funkciót
  html5Override?: boolean; // Ha igaz, akkor az alapértelmezett lejátszótípus HTML5-re állítja be
  controlBar: any;
  enableDocumentPictureInPicture?: boolean; // Ha igaz, akkor a lejátszó támogatja a dokumentum kép a képben funkcióját
  playsinline?: boolean; // Ha igaz, akkor a videó a böngészőben játszódik le, nem a teljes képernyőn
}

@Component({
  selector: 'app-vjs-player',
  template: `
    <video #target class="video-js" controls  preload="auto"></video>
  `,
  styleUrls: [
    './video-js.component.scss'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class VideoJsComponent implements OnInit, OnDestroy {
  @ViewChild('target', {static: true})
  target !: ElementRef;
  // See options: https://videojs.com/guides/options
  @Input({ required: true })
  options !: VideoJsOptions | undefined | null;
  @Input() posterUrl ?: string | undefined | null = 'assets/video-bg-default2.png';
  @Input() isViewedPercentage : number = 65;
  @Output() videoIsViewedEvent = new EventEmitter<boolean>();

  private player !: Player;
  private totalWatchedTime = 0;
  private isVideoIsViewed = false;

  constructor() {}

  // Instantiate a Video.js player OnInit
  ngOnInit() {
    if (!this.posterUrl || this.posterUrl === '') {
      this.posterUrl = videoPosterDefault;
    }
    this.initPlayer();
  }

  // Dispose the player OnDestroy
  ngOnDestroy() {
    this.destroyPlayer();
  }

  private timerUpdate() {
      const currentTime = this.player.currentTime() as number;
      const duration = this.player.duration() as number;

      if ((this.totalWatchedTime + currentTime) / duration >= (this.isViewedPercentage / 100)) {
        if (!this.isVideoIsViewed) {
          this.isVideoIsViewed = true;
          this.videoIsViewedEvent.emit(true);
        }
      }

      this.totalWatchedTime += currentTime;
  }

  private initPlayer() {
    this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
      console.log('onPlayerReady');
    });
    if(this.posterUrl) {
      this.player.poster(this.posterUrl);
    }
    this.player.on('timeupdate', this.timerUpdate.bind(this));
    this.player.on('touchstart', ($event: any) => {this.touchstart($event)});
  }

  private destroyPlayer() {
    if (this.player) {
      this.player.off('timeupdate', this.timerUpdate.bind(this));
      this.player.off('touchstart', this.touchstart.bind(this));
      this.player.dispose();
    }
  }

  // it is a hack form mobile, because its doesn't pause on tap on the video :(
  private touchstart($event: any) {
    const classList: DOMTokenList = $event.target.classList;

    if (classList.value.indexOf('icon') > -1) {
      return;
    }

    if (this.player.paused()) {
      this.player.play();
    } else {
      this.player.pause();
    }
  }
}
