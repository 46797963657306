import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-checkbox-toggle',
  templateUrl: './checkbox-toggle.component.html',
  styleUrls: ['./checkbox-toggle.component.scss'],
})
export class CheckboxToggleComponent  {
  @Input() checked = false;
  @Output() changedEmitter = new EventEmitter<string>();

  constructor() { }

  change($event: any) {
    this.changedEmitter.emit($event);
  }
}
