<div *ngIf="item" class="card d-flex w-100 shadow-basic"
     cdkOverlayOrigin #trigger="cdkOverlayOrigin"


>
  <div (click)="clickItem()" class="d-flex ion-align-items-center gap-10 clickable clickable-text-container">
    <ng-container *ngIf="item.progression?.status">

      <img *ngIf="item.progression.status === 'inactive'" class="pr-10" src="assets/icon/badges/course-progression/inactive.svg" alt="" />

      <img *ngIf="item.progression.status === 'active'" class="pr-10" src="assets/icon/badges/course-progression/active-v2.svg" alt="" />

      <img *ngIf="item.progression.status === 'completed'" class="pr-10" src="assets/icon/badges/course-progression/completed.svg" alt="" />

      <img *ngIf="item.progression.status === 'finished'" class="pr-10" src="assets/icon/badges/course-progression/finished.svg" alt="" />

      <img *ngIf="item.progression.status === 'postponed'" class="pr-10" src="assets/icon/badges/course-progression/postponed.svg" alt="" />

    </ng-container>
    <p class="blue-dark font-size fw-700 item-title" [ngClass]="{'item-title-free': item.isFree || item.is_paywalled === false}">
      {{ item.title }}
    </p>
    <span *ngIf="item.isFree || item.is_paywalled === false" class="d-flex-center dark-orange font-small-size fw-700 uppercase is-free"
    >szabad</span>
  </div>
  <img   (click)="clickItem(true)" src="/assets/icon/information.svg" alt="" />
</div>



<ng-template
  *ngIf="mode === 'trick'"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isModalShown"
>
  <app-modal
    [type]="'list-challange'"
    (closeEmitter)="isModalShown = !isModalShown"
  >
    <div class="modal-content">
      <div>
        {{ item.trickLongtext }}
      </div>
    </div>
  </app-modal>
</ng-template>
