<app-widget-container
  [title]="title"
  [noPadding]="true"
>
  <ion-row>
    <app-video
      [url]="video.url"
      [posterUrl]="video.posterUrl"
    ></app-video>
  </ion-row>
  <ion-row class="p-20">
    <app-button class="w-100" [color]="'orange'" (click)="detailsClick()" tappable>
      Részletek
    </app-button>
  </ion-row>
</app-widget-container>
