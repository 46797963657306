import { Component, Input, OnInit } from '@angular/core';
import {NavController, Platform} from "@ionic/angular";
import {PaymentService, SubscriptionData} from "~/src/app/shared/services/payment.service";
import {UserService} from "~/src/app/core/services/user.service";
import {environment} from "~/src/environments/environment";
import {Capacitor} from "@capacitor/core";

@Component({
  selector: 'app-subscription',
  template: `
    <div *ngIf="isSubscriptionActive !== null" class="modal shadow-basic subsciption-modal" style="{{isSubscriptionActive ? 'padding-bottom: 0' : ''}}">
      <div class="content-container">
        <ion-icon class="subsciption-icon" [name]="iconName" [color]="iconColor"></ion-icon>
        <div class="w-100" *ngIf="isSubscriptionActive; else inactiveContent">
          Az apparently közösség teljes körű tagja vagy!
          <br>
          <span *ngIf="subscriptionDate && !isPaymentDisabled">
            Érvényesség: {{ subscriptionDate }}
          </span>
          <div class="w-100 mt-20" *ngIf="!isOnlyShowDetailsButton && !isPaymentDisabled">
            <app-button class="w-100 " (click)="openUnsubscribePopup()" class="text-center white w-100 mt-20">Lemondom a tagságomat</app-button>
            <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOpen]="isUnsibscribePopupIsOpened"
            >
              <app-modal
                (closeEmitter)="isUnsibscribePopupIsOpened = false"
                [type]="'info-popup'"
              >
                <div class="modal-content">
                  <h3 class="title text-bold">Lemondom a tagságomat</h3>
                  <div class="text">
                    Sajnáljuk, ha le szeretnéd mondani apparently-tagságodat! Tudjuk, hogy sokszor nem könnyű, de egy kis türelemmel és kitartással hamar sokat javul a helyzet. Hidd el, érdemes még egy kis időt fektetni bele!

                    Ha mindenképp le szeretnéd mondani a tagságod, nagyon örülnénk, ha rövid visszajelzést adnál nekünk annak okáról!
                  </div>
                  <div class="buttons">
                    <div class="mt-20">
                      <div class="mt-10">
                        <app-button (click)="isUnsibscribePopupIsOpened = false">Mégse</app-button>
                      </div>
                      <div class="mt-10">
                        <a style="text-decoration: none" href="mailto: hello@apparently.hu">
                            <app-button [color]="'secondary'" (click)="unsubscribeApparently()">Lemondom a tagságomat</app-button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </app-modal>
            </ng-template>
          </div>
        </div>
        <ng-template #inactiveContent>
          Még nem vagy az apparently közösség teljes körű tagja.
        </ng-template>


        <ng-template #onlyShowDetailsButton>
          <app-button
            (click)="openExternalLink()"
            class="text-center white w-100 mt-20"
          >Részletek</app-button>
          <div  class="pb-20"></div>
        </ng-template>

        <ng-container class="w-100" *ngIf="!isOnlyShowDetailsButton; else onlyShowDetailsButton">

          <app-button
            *ngIf="!isSubscriptionActive && !isWeb"
            (click)="openExternalLink()"
            class="text-center white w-100 mt-20"
          >Részletek</app-button>


          <span class="w-100 mt-10" *ngFor="let setting of settings; let first = first;">
            <app-button *ngIf="!isSubscriptionActive && setting.is_on_sale && isWeb" class="w-100" [color]="first ? 'secondary' : 'primary'"
                        (click)="subscribe(setting.id)">
              {{ setting.name }} - <span class="price">{{ setting.current_price }} Ft</span>
            </app-button>
          </span>
        </ng-container>



      </div>
    </div>
  `,
  styles: [`
    .subsciption-modal {
      margin-bottom: 20px;
      margin-top: -10px;
    }
    .content-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    ion-card {
      max-width: 400px;
      margin: 20px auto;
    }
    .subsciption-icon {
      font-size: 45px;
      margin-bottom: 10px;
    }
    .modal {
      border-radius: 10px;
    }
    .price {
      font-size: var(--ion-font-size-small);
    }
  `]
})

export class SubscriptionComponent implements OnInit {
  @Input() isOnlyShowDetailsButton = false;
  isSubscriptionActive: boolean | null = null;
  subscriptionDate: string | null = null;
  isWeb;
  isUnsibscribePopupIsOpened = false;
  settings: any;
  isPaymentDisabled = localStorage.getItem('isPaymentDisabled');

  constructor(
      private navCtr: NavController,
      private paymentService: PaymentService,
      private userSevice: UserService
  ) {
    const isNative = Capacitor.isNativePlatform();

    this.isWeb = !isNative;
  }
  get iconName(): string {
    return this.isSubscriptionActive ? 'checkmark-circle' : 'people-outline';
  }

  get iconColor(): string {
    return this.isSubscriptionActive ? 'success' : '#2e5180';
  }

  async ngOnInit() {
    // const products = await this.paymentService.getAllproducts().toPromise();
    const subscriptionData = await this.userSevice.getSubscriptionData();

    if (subscriptionData) {
      this.isSubscriptionActive = subscriptionData.isActive;
      this.subscriptionDate = subscriptionData.expirationDate;
    }

    this.getSettings();
  }

  openExternalLink() {
    const url = environment.application.protocol + '://' + environment.application.domain + '/pages/profile/subscription?token=' + localStorage.getItem('jwtToken');

    if (this.isWeb) {
      this.navCtr.navigateForward('/pages/profile/subscription');
    } else {
      window.open(url, '_blank');
    }
  }


  subscribe(productId: number) {
    this.paymentService.subscribe(productId).subscribe((res: SubscriptionData<any>) => {
      location.href = res.redirect_url;
    });
  }

  private getSettings() {
    return this.paymentService.getSettings().subscribe((res: any) => {
      if (!res || !res.length) {
        return;
      }
      this.settings = res.slice().reverse();
    });
  }

  unsubscribeApparently() {
    this.paymentService.cancelSubscription().subscribe(() => {
      this.isSubscriptionActive = false;
    });
  }

  openUnsubscribePopup() {
    this.isUnsibscribePopupIsOpened = true;
  }
}
